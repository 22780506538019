<template>
  <PageContentContainer>
    <div v-if="!!user.id && !!calendar" ref="parentDiv" class="py-4 relative">
      <h1 class="font-bold mt-4 mb-4">{{ $t('challengeSolvesCalendar.heading') }}</h1>

      <p>
        {{ $t('challengeSolvesCalendar.description') }}
      </p>

      <div v-if="calendar.categories.length > 0" class="mx-auto mt-4">
        <table class="min-w-96 w-96 table-auto">
          <tbody>
            <template v-for="(category, categoryIndex) in calendar.categories" :key="categoryIndex">
              <tr class="category-header">
                <td class="border-b-light-100 border-b-width-[1px] whitespace-nowrap" colspan="100%">
                  <strong>{{ $t('general.category') }} {{ category.name }}</strong>
                </td>
              </tr>
              <tr v-if="category.challenges.length === 0">
                <td><!-- challenge name --></td>
                <td :colspan="allDays.length" class="text-center text-muted whitespace-nowrap">{{ $t('challengeSolvesCalendar.noChallenges') }}</td>
              </tr>
              <template v-for="(challenge, challengeIndex) in category.challenges" v-else :key="challengeIndex">
                <template v-if="challenge.flags.length > 0">
                  <tr
                    v-for="(flag, flagIndex) in challenge.flags"
                    :key="flagIndex"
                    :class="{
                      // Add some padding.
                      'challenge-start': flagIndex === 0,
                      'challenge-end': flagIndex === challenge.flags.length - 1,
                    }"
                  >
                    <td v-if="flagIndex === 0" :rowspan="challenge.flags.length" class="pr-4 text-right whitespace-nowrap">
                      {{ challenge.name }}
                    </td>
                    <td
                      v-for="day in allDays"
                      :key="day.getTime()"
                    >
                      <div
                        class="solve-calendar-day block m-0.5 w-3 h-3 rounded-sm transition-colors cursor-default"
                        :style="{
                          backgroundColor: solvesToColor(flag.solvesPerDay.get(day.getTime()) ?? 0),
                          strokeWidth: CELL_SPACING,
                        }"
                        :data-solves="flag.solvesPerDay.get(day.getTime()) ?? 0"
                        :data-date="day.toLocaleDateString()"
                        :data-flagindex="flagIndex + 1"
                        :data-numflags="challenge.flags.length"
                        @mouseenter="openTooltip"
                        @mouseleave="closeTooltip"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="challenge-start challenge-end">
                    <td class="pr-4 text-right whitespace-nowrap">
                      {{ challenge.name }}
                    </td>
                    <td :colspan="allDays.length" class="text-center text-muted whitespace-nowrap">{{ $t('challengeSolvesCalendar.noFlags') }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <span v-if="calendar && calendar.categories.length === 0" class="block mx-auto text-center text-xl py-4">
        {{ $t('challengeSolvesCalendar.noChallenges') }}
      </span>
      <div ref="tooltip" class="calendar-tooltip px-3 py-2 rounded bg-gray-800 fit-content pointer-events-none">
        <template v-if="tooltipData">
          {{ $t('challengeSolvesCalendar.tooltip.flagIndex', { n: tooltipData.flagindex, m: tooltipData.numflags }) }}
          <br>
          <span class="whitespace-nowrap">
            <strong>{{ tooltipData.solves }}</strong> {{ $t('general.solve', parseInt(tooltipData.solves || 'NaN')) }}
            {{ $t('challengeSolvesCalendar.tooltip.on') }} {{ tooltipData.date }}
          </span>
        </template>
      </div>
    </div>
    <div v-else class="w-full">
      <Spinner class="mx-auto w-6 h-6" />
    </div>
  </PageContentContainer>
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue'

  import PageContentContainer from '@/components/PageContentContainer.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { useApi, useMetadata, usePeriodic, useUser } from '@/hooks'

  const { user } = useUser()
  const { metadata } = useMetadata()

  type Calendar = {
    categories: {
      name: string,
      challenges: {
        name: string,
        flags: {
          solvesPerDay: Map<number, number>,
        }[],
      }[]
    }[]
  }

  const calendar = ref<null | Calendar>(null)

  const tooltip = ref<HTMLElement>()
  const parentDiv = ref<HTMLElement>()
  const tooltipData = ref<DOMStringMap>()
  const CELL_DIMENSIONS_PX = 14
  const CELL_SPACING = CELL_DIMENSIONS_PX * 0.2

  const api = useApi()

  usePeriodic(update, FLUGSICHERUNG_RELOAD_DATA_INTERVAL * 5)

  const allDays = computed(() => {
    const days: Date[] = []

    if (metadata.value.ctfEnd.getTime() < metadata.value.ctfStart.getTime()) {
      throw new Error('CTF cannot end before it has begun.')
    }

    // All of this is done in the local timezone to show the user's perspective.
    let current = new Date(metadata.value.ctfStart)
    current.setHours(0, 0, 0, 0)
    const end = new Date(metadata.value.ctfEnd)
    end.setHours(0, 0, 0, 0)
    while (current.getTime() <= end.getTime()) {
      days.push(current)
      current = new Date(current)
      // This should (hopefully) be time zone safe.
      // Adding a day to the last day of the month also
      // wraps around to the next month.
      // Fuck DST.
      current.setDate(current.getDate() + 1)
      current.setHours(0, 0, 0, 0)
    }
    return days
  })

  /**
   * Returns the timestamp of the day's start.
   *
   * @param date The date to get the day of.
   */
  function getDay(date: Date | string): Date {
    const day = new Date(date)
    day.setHours(0, 0, 0, 0)
    return day
  }

  /* Updates the challenge solves data. */
  async function update() {
    try {
      const solveTimes = await api.value.score.v1StatsSolvetimesGet()

      const categories: Calendar['categories'] = solveTimes.categories.map((category) => {
        return {
          name: category.name,
          challenges: category.challengeSolves.map((challenge) => {
            const flags = challenge.flagSolves.map((solves) => {
              const days = solves.solveTimes.map(getDay)
              // Reduce to count per day.
              const solvesPerDay = new Map<number, number>()
              for (const day of days) {
                solvesPerDay.set(day.getTime(), (solvesPerDay.get(day.getTime()) ?? 0) + 1)
              }
              return {
                solvesPerDay,
              }
            })
            return {
              name: challenge.name,
              flags,
            }
          }),
        }
      })

      calendar.value = {
        categories,
      } satisfies Calendar

      calendar.value.categories.sort((catA, catB) => catA.name.localeCompare(catB.name))
      calendar.value.categories.forEach((category) => {
        category.challenges.sort((challengeA, challengeB) => challengeA.name.localeCompare(challengeB.name))
      })
    } catch {}
  }

  /** The maximum number of solves for any flag on any day. Used for the color scale. */
  const maximumSolves = computed(() => {
    if (!calendar.value) {
      return 0
    }
    return Math.max(
      ...calendar.value.categories
        .flatMap((category) => category.challenges
          .flatMap((challenge) => challenge.flags
            .flatMap((flag) => [...flag.solvesPerDay.values()]),
          ),
        ),
    )
  })

  /* Linear IntERPolation between start and end according to fraction. */
  function lerp(start: number, end: number, fraction: number): number {
    return start + fraction * (end - start)
  }

  /* Returns the color to use for the heatmap given an absolute number of solves. */
  function solvesToColor(solves: number): string {
    const fraction = solves / maximumSolves.value
    if (solves === 0) {
      return 'rgb(60, 60, 80)'
    }
    const r = lerp(128, 64, fraction)
    const g = lerp(100, 255, fraction)
    const b = lerp(128, 64, fraction)
    return `rgb(${r},${g},${b})`
  }

  function positionTooltip(event: MouseEvent) {
    if (!tooltip.value || !parentDiv.value) {
      return
    }

    // We need to position the tooltip relatively to the parent.
    const parent = (event.target as HTMLElement).parentElement
    if (!parent) {
      return
    }
    const parentRect = parentDiv.value.getBoundingClientRect()
    let x = Math.max(event.clientX - parentRect.left, 0)
    let y = Math.max(event.clientY - parentRect.top, 0)
    tooltip.value.style.left = `${x}px`
    tooltip.value.style.top = `${y}px`

    // Correct for out of bounds at page borders.
    let tooltipRect = tooltip.value.getBoundingClientRect()

    const parentRightDiff = parentRect.right - tooltipRect.right
    if (parentRightDiff < 0) {
      x += parentRightDiff - 5
      tooltip.value.style.left = `${x}px`
      tooltipRect = tooltip.value.getBoundingClientRect()
    }
    const windowRightDiff = window.innerWidth - tooltipRect.right
    if (windowRightDiff < 0) {
      x += windowRightDiff - 5
      tooltip.value.style.left = `${x}px`
      tooltipRect = tooltip.value.getBoundingClientRect()
    }
    const parentBottomDiff = parentRect.bottom - tooltipRect.bottom
    if (parentBottomDiff < 0) {
      y += parentBottomDiff - 5
      tooltip.value.style.top = `${y}px`
      tooltipRect = tooltip.value.getBoundingClientRect()
    }
    const windowBottomDiff = window.innerHeight - tooltipRect.bottom
    if (windowBottomDiff < 0) {
      y += windowBottomDiff - 5
      tooltip.value.style.top = `${y}px`
    }
  }

  function openTooltip(event: MouseEvent) {
    if (!tooltip.value) {
      return
    }

    const target = event.target as HTMLElement
    tooltipData.value = target.dataset

    positionTooltip(event)
    tooltip.value.style.visibility = 'visible'
  }

  function closeTooltip() {
    if (!tooltip.value) {
      return
    }
    tooltip.value.style.visibility = 'hidden'
  }
</script>

<style scoped lang="scss">
.calendar {
  @apply w-full;
}

.solve-calendar-day {
  stroke: black;
}

.solve-calendar-day-label {
  fill: white;
}

.calendar-tooltip {
  position: absolute;
  visibility: hidden;
  text-align: center;
}

$category-spacing: 0.5rem;
$challenge-spacing: 0.5rem;
.challenge-start {
  td {
    padding-top: $challenge-spacing;
  }

  td:first-child {
    padding-bottom: $challenge-spacing;
  }
}

.challenge-end {
  &:not(:last-child) {
    td {
      padding-bottom: $challenge-spacing;
    }
  }

  td:last-child {
    @apply w-full;
  }
}

.category-header:not(:first-child) {
  td {
    padding-top: $category-spacing;
  }
}

.fit-content {
  width: fit-content;
}
</style>
