/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HandlerCategoryResponse,
  HandlerErrorResponse,
} from '../models/index';
import {
    HandlerCategoryResponseFromJSON,
    HandlerCategoryResponseToJSON,
    HandlerErrorResponseFromJSON,
    HandlerErrorResponseToJSON,
} from '../models/index';

export interface V1CategoriesSlugGetRequest {
    slug: string;
}

/**
 * 
 */
export class CategoriesApi extends runtime.BaseAPI {

    /**
     * Get all categories.
     */
    async v1CategoriesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HandlerCategoryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/categories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HandlerCategoryResponseFromJSON));
    }

    /**
     * Get all categories.
     */
    async v1CategoriesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HandlerCategoryResponse>> {
        const response = await this.v1CategoriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get one category.
     */
    async v1CategoriesSlugGetRaw(requestParameters: V1CategoriesSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerCategoryResponse>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1CategoriesSlugGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/categories/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Get one category.
     */
    async v1CategoriesSlugGet(requestParameters: V1CategoriesSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerCategoryResponse> {
        const response = await this.v1CategoriesSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
