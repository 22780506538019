<template>
  <svg :class="'animate-spin ' + props.class" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-30" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
    <path
      class="opacity-80"
      fill="currentColor"
      d="M 3.8322408,12.031297 C 3.8322408,7.6130188 7.581722,4 12,4 12,4 12.903708,4.0680054 12.903708,2.0997297
      12.903708,0.13145402 12,0 12,0 5.373,0 0,5.373 0,12 c 0,0 0.39005866,0.849007 2.0010974,0.851125 1.6110387,0.0021
      1.8311434,-0.819828 1.8311434,-0.819828 z"
    />
  </svg>
</template>

<script lang="ts" setup>
  import { defineProps, withDefaults } from 'vue'

  const props = withDefaults(defineProps<{
    class: string,
  }>(), {
    class: '',
  })
</script>
