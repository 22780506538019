<template>
  <Modal :modelValue="props.modelValue" :title="$t('vpn.modalTitle')" @update:model-value="emits('update:modelValue', $event)">
    <div
      v-if="!props.vpnConnected"
      class="bg-black bg-opacity-35 border-2 border-l-5 border-error rounded-md text-white px-3 py-3 mx-3 my-5 flex items-center"
    >
      <div class="flex items-center w-full">
        <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none">
          <ExclamationTriangleIcon />
        </div>
        <div class="flex-1">
          <p>
            <b>{{ $t('vpn.notConnected') }}</b>
            <br>
            {{ $t('vpn.pleaseFollow') }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-else
      class="bg-black bg-opacity-35 border-2 border-l-5 border-success rounded-md text-white px-3 py-3 mx-3 my-5 flex items-center"
    >
      <div class="flex items-center w-full">
        <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none">
          <CheckBadgeIcon />
        </div>
        <div class="flex-1">
          <h1 class="font-bold text-xl pb-2">{{ $t('vpn.yourIP') }}: <span class="text-mono">{{ vpnIP }}</span></h1>
          <p>
            {{ $t('vpn.workingConnection') }}
            <br>
            {{ $t('vpn.dontFollow') }}
          </p>
          <p class="pt-2">
            <i18n-t tag="b" keypath="vpn.onlyOneDevice" scope="global">
              <template v-slot:oneUnderlining>
                <em class="underline">{{ $t('vpn.onlyOneDeviceOne') }}</em>
              </template>
            </i18n-t>
          </p>
        </div>
      </div>
    </div>

    <div class="install-step">
      <div class="step-icon">
        <CogIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('vpn.guide.install.title') }}</h3>
        <i18n-t tag="p" keypath="vpn.guide.install.description" scope="global">
          <template v-slot:docsLink>
            <a href="https://www.wireguard.com/install/" target="_blank">{{ $t('vpn.guide.install.docs') }}</a>
          </template>
        </i18n-t>
      </div>
    </div>

    <div class="install-step">
      <div class="step-icon">
        <ArrowDownTrayIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('vpn.guide.getConfig.title') }}</h3>
        <p>
          <button class="bg-primaryBg hover:bg-primaryBgHover border-primary border-2 rounded px-2 py-1" @click="downloadVPNConfig">
            {{ $t('vpn.guide.getConfig.description') }}
          </button>
        </p>
      </div>
    </div>

    <div class="install-step">
      <div class="step-icon">
        <ForwardIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('vpn.guide.startVPN.title') }}</h3>
        <div class="os-selector">
          <div class="inline-flex" role="group">
            <button type="button" class="rounded-l" :class="{active: osSelector === Os.Linux}" @click="osSelector = Os.Linux">Linux</button>
            <button type="button" :class="{active: osSelector === Os.MacOS}" @click="osSelector = Os.MacOS">macOS</button>
            <button type="button" class="rounded-r" :class="{active: osSelector === Os.Windows}" @click="osSelector = Os.Windows">Windows</button>
          </div>
        </div>

        <InplaceReplaceTransition>
          <div v-if="osSelector === Os.Linux">
            <ol>
              <li>
                {{ $t('vpn.guide.startVPN.linux.copy') }}
                <code>/etc/wireguard/{{ VPN_CONFIG_FILE_NAME }}.{{ VPN_CONFIG_FILE_EXTENSION }}</code>
                <br>
                <em>{{ $t('vpn.guide.startVPN.linux.rootPermissions') }}</em>
              </li>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.linux.run" scope="global">
                <template v-slot:cmd>
                  <code>wg-quick up {{ VPN_CONFIG_FILE_NAME }}</code>
                </template>
              </i18n-t>
            </ol>
          </div>
          <div v-if="osSelector === Os.MacOS">
            <ol>
              <li>{{ $t('vpn.guide.startVPN.macOS.open') }}</li>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.macOS.addConfig" scope="global">
                <template v-slot:cmdO><code>⌘+O</code></template>
                <template v-slot:plus><code>+</code></template>
                <template v-slot:import><em>Import Tunnel(s) from File...</em></template>
              </i18n-t>
              <li>{{ $t('vpn.guide.startVPN.macOS.selectDownloadedFile') }}</li>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.macOS.addConfig" scope="global">
                <template v-slot:name><em>{{ VPN_CONFIG_FILE_NAME }}</em></template>
                <template v-slot:activate><em>Activate</em></template>
              </i18n-t>
            </ol>
          </div>
          <div v-if="osSelector === Os.Windows">
            <ol>
              <li>{{ $t('vpn.guide.startVPN.windows.open') }}</li>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.windows.addTunnel" scope="global">
                <template v-slot:addTunnel><em>Add Tunnel</em></template>
              </i18n-t>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.windows.chooseTunnel" scope="global">
                <template v-slot:tunnelName><em>{{ VPN_CONFIG_FILE_NAME }}</em></template>
              </i18n-t>
              <i18n-t tag="li" keypath="vpn.guide.startVPN.windows.activate" scope="global">
                <template v-slot:activate><em>Activate</em></template>
              </i18n-t>
            </ol>
          </div>
        </InplaceReplaceTransition>
      </div>
    </div>

    <div class="install-step">
      <div class="step-icon">
        <CheckBadgeIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('vpn.guide.success.title') }}</h3>
        <p>{{ $t('vpn.guide.success.description') }}</p>
        <div
          class="bg-black bg-opacity-35 border-2 border-l-5 border-orange-500 rounded-md text-white px-3 py-3 my-3 flex items-center"
        >
          <div class="flex items-center w-full">
            <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none">
              <ExclamationCircleIcon />
            </div>
            <div class="flex-1">
              <p>
                <i18n-t tag="b" keypath="vpn.onlyOneDevice" scope="global">
                  <template v-slot:oneUnderlining>
                    <em class="underline">{{ $t('vpn.onlyOneDeviceOne') }}</em>
                  </template>
                </i18n-t>
                <br>
                {{ $t('vpn.onlyOneDeviceExplanation') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
  import {
    ArrowDownTrayIcon,
    CheckBadgeIcon,
    CogIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    ForwardIcon,
  } from '@heroicons/vue/24/outline'
  import { defineProps, defineEmits, withDefaults, ref } from 'vue'

  import InplaceReplaceTransition from '@/components/ui/InplaceReplaceTransition.vue'
  import Modal from '@/components/ui/Modal.vue'
  import { VPN_CONFIG_FILE_NAME, VPN_CONFIG_FILE_EXTENSION } from '@/constants'
  import { useApi } from '@/hooks/auth'
  import { saveAs } from '@/util/file'

  const api = useApi()

  const props = withDefaults(defineProps<{
    modelValue: boolean,
    vpnConnected: boolean,
    vpnIP: string,
  }>(), {
    modelValue: false,
    vpnConnected: false,
    vpnIP: '',
  })

  const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

  enum Os {
    Linux,
    MacOS,
    Windows,
  }

  const osSelector = ref<Os>(Os.Linux)

  async function downloadVPNConfig() {
    if (!api) {
      throw new Error('Cannot get authenticated API.')
    }
    const response = await api.value.users.v1UserVpnConfigGet()
    saveAs(response, `${VPN_CONFIG_FILE_NAME}.${VPN_CONFIG_FILE_EXTENSION}`)
  }

</script>

<style scoped lang="scss">
  .install-step {
    @apply flex my-5;

    .step-icon {
      @apply py-1 mr-3 h-7 w-7 flex-none;
    }

    .step-description {
      @apply flex-1;

      h3 {
        @apply font-bold text-xl mt-1 mb-2;
      }

      .os-selector {
        @apply flex items-center justify-center py-3;

        button {
          @apply inline-block px-4 py-1 border-2;

          // Inactive styles
          @apply bg-accentBg hover:bg-accentHover border-gray-600;

          &.active {
            @apply bg-primaryBg hover:bg-primaryBgHover border-primary;
          }
        }
      }
    }
  }
</style>
