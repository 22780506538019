/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HandlerChallengeScoreDto,
  HandlerErrorResponse,
  HandlerScoreboardDto,
  HandlerSolveTimesDto,
} from '../models/index';
import {
    HandlerChallengeScoreDtoFromJSON,
    HandlerChallengeScoreDtoToJSON,
    HandlerErrorResponseFromJSON,
    HandlerErrorResponseToJSON,
    HandlerScoreboardDtoFromJSON,
    HandlerScoreboardDtoToJSON,
    HandlerSolveTimesDtoFromJSON,
    HandlerSolveTimesDtoToJSON,
} from '../models/index';

export interface V1ChallengesSlugScoreGetRequest {
    slug: string;
    category: string;
}

/**
 * 
 */
export class ScoreApi extends runtime.BaseAPI {

    /**
     * Retrieves the current participant\'s score for the given challenge.
     * Get score for a particular challenge.
     */
    async v1ChallengesSlugScoreGetRaw(requestParameters: V1ChallengesSlugScoreGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerChallengeScoreDto>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugScoreGet().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugScoreGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}/score`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerChallengeScoreDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves the current participant\'s score for the given challenge.
     * Get score for a particular challenge.
     */
    async v1ChallengesSlugScoreGet(requestParameters: V1ChallengesSlugScoreGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerChallengeScoreDto> {
        const response = await this.v1ChallengesSlugScoreGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get scoreboard.
     */
    async v1StatsScoreboardGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerScoreboardDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/stats/scoreboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerScoreboardDtoFromJSON(jsonValue));
    }

    /**
     * Get scoreboard.
     */
    async v1StatsScoreboardGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerScoreboardDto> {
        const response = await this.v1StatsScoreboardGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get statistics about when which flags were submitted.
     */
    async v1StatsSolvetimesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerSolveTimesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/stats/solvetimes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerSolveTimesDtoFromJSON(jsonValue));
    }

    /**
     * Get statistics about when which flags were submitted.
     */
    async v1StatsSolvetimesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerSolveTimesDto> {
        const response = await this.v1StatsSolvetimesGetRaw(initOverrides);
        return await response.value();
    }

}
