/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HandlerChallengeInstanceDto,
  HandlerChallengeResponse,
  HandlerErrorResponse,
  HandlerFlagSubmission,
  HandlerFlagSubmissionResponse,
  HandlerSingleChallengeResponse,
} from '../models/index';
import {
    HandlerChallengeInstanceDtoFromJSON,
    HandlerChallengeInstanceDtoToJSON,
    HandlerChallengeResponseFromJSON,
    HandlerChallengeResponseToJSON,
    HandlerErrorResponseFromJSON,
    HandlerErrorResponseToJSON,
    HandlerFlagSubmissionFromJSON,
    HandlerFlagSubmissionToJSON,
    HandlerFlagSubmissionResponseFromJSON,
    HandlerFlagSubmissionResponseToJSON,
    HandlerSingleChallengeResponseFromJSON,
    HandlerSingleChallengeResponseToJSON,
} from '../models/index';

export interface V1ChallengesSlugGetRequest {
    slug: string;
    category: string;
}

export interface V1ChallengesSlugInstanceDeleteRequest {
    slug: string;
    category: string;
}

export interface V1ChallengesSlugInstanceGetRequest {
    slug: string;
    category: string;
}

export interface V1ChallengesSlugInstanceProlongPostRequest {
    slug: string;
    category: string;
}

export interface V1ChallengesSlugInstancePutRequest {
    slug: string;
    category: string;
}

export interface V1ChallengesSlugSolvePostRequest {
    slug: string;
    category: string;
    flag: HandlerFlagSubmission;
}

/**
 * 
 */
export class ChallengesApi extends runtime.BaseAPI {

    /**
     * Get all challenges.
     */
    async v1ChallengesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HandlerChallengeResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HandlerChallengeResponseFromJSON));
    }

    /**
     * Get all challenges.
     */
    async v1ChallengesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HandlerChallengeResponse>> {
        const response = await this.v1ChallengesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get one challenge.
     */
    async v1ChallengesSlugGetRaw(requestParameters: V1ChallengesSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerSingleChallengeResponse>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugGet().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerSingleChallengeResponseFromJSON(jsonValue));
    }

    /**
     * Get one challenge.
     */
    async v1ChallengesSlugGet(requestParameters: V1ChallengesSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerSingleChallengeResponse> {
        const response = await this.v1ChallengesSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stop a challenge for the current user (if running).
     */
    async v1ChallengesSlugInstanceDeleteRaw(requestParameters: V1ChallengesSlugInstanceDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugInstanceDelete().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugInstanceDelete().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}/instance`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stop a challenge for the current user (if running).
     */
    async v1ChallengesSlugInstanceDelete(requestParameters: V1ChallengesSlugInstanceDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ChallengesSlugInstanceDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get the instance of a challenge for the current user. (Is it stopped, starting, etc.)
     */
    async v1ChallengesSlugInstanceGetRaw(requestParameters: V1ChallengesSlugInstanceGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerChallengeInstanceDto>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugInstanceGet().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugInstanceGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}/instance`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerChallengeInstanceDtoFromJSON(jsonValue));
    }

    /**
     * Get the instance of a challenge for the current user. (Is it stopped, starting, etc.)
     */
    async v1ChallengesSlugInstanceGet(requestParameters: V1ChallengesSlugInstanceGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerChallengeInstanceDto> {
        const response = await this.v1ChallengesSlugInstanceGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prolong a challenge instance.
     */
    async v1ChallengesSlugInstanceProlongPostRaw(requestParameters: V1ChallengesSlugInstanceProlongPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugInstanceProlongPost().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugInstanceProlongPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}/instance/prolong`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Prolong a challenge instance.
     */
    async v1ChallengesSlugInstanceProlongPost(requestParameters: V1ChallengesSlugInstanceProlongPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ChallengesSlugInstanceProlongPostRaw(requestParameters, initOverrides);
    }

    /**
     * Start a challenge for the current user (if not already running).
     */
    async v1ChallengesSlugInstancePutRaw(requestParameters: V1ChallengesSlugInstancePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugInstancePut().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugInstancePut().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/challenges/{slug}/instance`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start a challenge for the current user (if not already running).
     */
    async v1ChallengesSlugInstancePut(requestParameters: V1ChallengesSlugInstancePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1ChallengesSlugInstancePutRaw(requestParameters, initOverrides);
    }

    /**
     * Submit a flag.
     */
    async v1ChallengesSlugSolvePostRaw(requestParameters: V1ChallengesSlugSolvePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerFlagSubmissionResponse>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling v1ChallengesSlugSolvePost().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling v1ChallengesSlugSolvePost().'
            );
        }

        if (requestParameters['flag'] == null) {
            throw new runtime.RequiredError(
                'flag',
                'Required parameter "flag" was null or undefined when calling v1ChallengesSlugSolvePost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/challenges/{slug}/solve`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HandlerFlagSubmissionToJSON(requestParameters['flag']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerFlagSubmissionResponseFromJSON(jsonValue));
    }

    /**
     * Submit a flag.
     */
    async v1ChallengesSlugSolvePost(requestParameters: V1ChallengesSlugSolvePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerFlagSubmissionResponse> {
        const response = await this.v1ChallengesSlugSolvePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
