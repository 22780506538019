<template>
  <slot />
</template>

<script setup lang="ts">
  import { onMounted, provide, readonly, ref } from 'vue'

  import { CompetitionMetadata, useApi } from '@/hooks'
  import { COMPETITION_METADATA_INJECTION_TOKEN } from '@/injectionTokens'
  import { isScoreGroupSelectionError, isTimeRestrictedError, extractErrorResponse } from '@/util/error'

  const api = useApi()
  const metadata = ref(
    new CompetitionMetadata(
      false,
      new Date(),
      new Date(),
      new Date(),
      '',
      false,
    ),
  )

  async function reloadMetadata() {
    const fetchedMetadata = await api.value.default.v1MetadataGet()

    let flugzeugIsEnabled = false
    try {
      flugzeugIsEnabled = (await api.value.users.v1UserFlugzeugEnabledGet()).enabled
    } catch (ex) {
      const err = await extractErrorResponse(ex)
      const shouldIgnoreError = isScoreGroupSelectionError(err) || isTimeRestrictedError(err)

      if (!shouldIgnoreError) {
        throw ex
      }
    }
    metadata.value = new CompetitionMetadata(
      true,
      new Date(fetchedMetadata.ctfStart),
      new Date(fetchedMetadata.ctfEnd),
      new Date(fetchedMetadata.ctfPostPlayEnd),
      fetchedMetadata.flagPrefix,
      flugzeugIsEnabled,
    )
  }

  onMounted(async () => {
    await reloadMetadata()
  })

  async function triggerRefetch() {
    await reloadMetadata()
  }

  provide(
    COMPETITION_METADATA_INJECTION_TOKEN,
    {
      metadata: readonly(metadata),
      refetch: triggerRefetch,
    },
  )
</script>
