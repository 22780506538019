import { Ref, ShallowRef, onBeforeUnmount, onMounted, readonly, ref, shallowRef } from 'vue'

type PeriodicCallback<T> = () => Promise<T> | T

type Periodic<T> = {
  /**
   * Trigger forces the callback to be executed and resets the timeout.
   */
  trigger: () => Promise<void>,
  /**
   * Resets the error and restarts the periodic.
   */
  reset: () => void,
  /**
   * When the callback threw an error, the error is stored here.
   */
  lastError: Readonly<Ref<Readonly<unknown>>>,
  /**
   * Holds the value of the callback.
   */
  value: ReturnType<typeof readonly<ReturnType<typeof shallowRef<T | null>>>>,
  /**
   * Whether the callback is currently being executed.
   */
  isLoading: Readonly<Ref<boolean>>
}

export const usePeriodic = <T>(
  cb: PeriodicCallback<T>,
  periodMs: number,
): Periodic<T> => {
  const lastError = ref<unknown>(null)
  const value = shallowRef<T | null>(null) as ShallowRef<T | null>
  const isLoading = ref(false)
  // The abortController helps to abort already running periodic fetches.
  const abortController = ref(new AbortController())

  const restartPeriodic = async () => {
    if (!abortController.value.signal.aborted) {
      // Prevent multiple loops from running.
      abortController.value.abort('restarting periodic fetch')
    }
    abortController.value = new AbortController()
    const periodic = async (abortSignal: AbortSignal) => {
      if (abortSignal.aborted) {
        return
      }
      try {
        isLoading.value = true
        value.value = await Promise.resolve(cb())
      } catch (ex) {
        lastError.value = ex
      } finally {
        isLoading.value = false
      }
      const timeout = setTimeout(() => { periodic(abortSignal) }, periodMs)
      abortSignal.addEventListener('abort', () => {
        clearTimeout(timeout)
      })
      if (lastError.value !== null || abortSignal.aborted) {
        clearTimeout(timeout)
      }
    }
    await periodic(abortController.value.signal)
  }

  // Lifecycle management.
  onMounted(restartPeriodic)
  onBeforeUnmount(() => {
    abortController.value.abort('stopping periodic because of unmount')
  })

  // Additional utility functions.
  const trigger = async () => {
    abortController.value.abort('refetch triggered')
    await restartPeriodic()
  }

  const reset = () => {
    lastError.value = null
    restartPeriodic()
  }

  return {
    lastError: readonly(lastError),
    value: readonly(value),
    trigger,
    reset,
    isLoading: readonly(isLoading),
  }
}
