/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChallengeDifficulty } from './ChallengeDifficulty';
import {
    ChallengeDifficultyFromJSON,
    ChallengeDifficultyFromJSONTyped,
    ChallengeDifficultyToJSON,
} from './ChallengeDifficulty';

/**
 * The response for a single challenge.
 * @export
 * @interface HandlerChallengeResponse
 */
export interface HandlerChallengeResponse {
    /**
     * The unique slug of the category.
     * @type {string}
     * @memberof HandlerChallengeResponse
     */
    categorySlug: string;
    /**
     * The difficulty of the challenge.
     * @type {ChallengeDifficulty}
     * @memberof HandlerChallengeResponse
     */
    difficulty: ChallengeDifficulty;
    /**
     * The name of the challenge.
     * @type {string}
     * @memberof HandlerChallengeResponse
     */
    name: string;
    /**
     * The order of the challenge within the category.
     * @type {number}
     * @memberof HandlerChallengeResponse
     */
    order: number;
    /**
     * The slug of the challenge. It is unique for its category.
     * @type {string}
     * @memberof HandlerChallengeResponse
     */
    slug: string;
}

/**
 * Check if a given object implements the HandlerChallengeResponse interface.
 */
export function instanceOfHandlerChallengeResponse(value: object): value is HandlerChallengeResponse {
    if (!('categorySlug' in value) || value['categorySlug'] === undefined) return false;
    if (!('difficulty' in value) || value['difficulty'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function HandlerChallengeResponseFromJSON(json: any): HandlerChallengeResponse {
    return HandlerChallengeResponseFromJSONTyped(json, false);
}

export function HandlerChallengeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerChallengeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'categorySlug': json['categorySlug'],
        'difficulty': ChallengeDifficultyFromJSON(json['difficulty']),
        'name': json['name'],
        'order': json['order'],
        'slug': json['slug'],
    };
}

export function HandlerChallengeResponseToJSON(value?: HandlerChallengeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categorySlug': value['categorySlug'],
        'difficulty': ChallengeDifficultyToJSON(value['difficulty']),
        'name': value['name'],
        'order': value['order'],
        'slug': value['slug'],
    };
}

