<template>
  <Header :mobile-menu-open="mobileMenuState" @toggle-mobile-menu="handleMenuToggle" />
  <Menu :mobile-open="mobileMenuState" />
  <div
    class="content-wrapper md:ml-[var(--sidebar-width)] flex flex-col relative z-0
    w-full md:w-[calc(100%-var(--sidebar-width))]
    min-h-[calc(100%-var(--header-height))] max-h-[calc(100%-var(--header-height))] h-[calc(100%-var(--header-height))]
    overflow-y-clip
    "
  >
    <div
      v-if="metadata.isInitialized && metadata.ctfHasEnded"
      class="bg-accentBg border-2 border-l-5 border-white rounded-md text-white px-3 py-3 mx-4 my-5 items-center"
    >
      <div class="flex items-center w-full">
        <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none">
          <ClockIcon />
        </div>
        <div class="flex-1">
          <p>{{ $t('navigation.notifications.ctfEnded') }}</p>
        </div>
      </div>
    </div>
    <div
      class="grow w-full flex flex-col overflow-y-auto"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ClockIcon } from '@heroicons/vue/24/outline'
  import { ref } from 'vue'

  import Header from '@/components/Header.vue'
  import Menu from '@/components/Menu.vue'
  import { useMetadata } from '@/hooks'

  const { metadata } = useMetadata()

  const mobileMenuState = ref(false)
  const handleMenuToggle = (newState: boolean) => {
    mobileMenuState.value = newState
  }
</script>
