import { inject } from 'vue'

import { COMPETITION_METADATA_INJECTION_TOKEN } from '@/injectionTokens'

export class CompetitionMetadata {
  // The constructor is not useless. It provides the variables.
  // eslint-disable-next-line no-useless-constructor
  constructor(
    readonly isInitialized: boolean,
    readonly ctfStart: Date,
    readonly ctfEnd: Date,
    readonly ctfPostPlayEnd: Date,
    readonly flagPrefix: string,
    readonly isFlugzeugEnabled: boolean,
  ) {}

  public get ctfHasStarted(): boolean {
    return Date.now() >= this.ctfStart.getTime()
  }

  public get ctfHasEnded(): boolean {
    return Date.now() >= this.ctfEnd.getTime()
  }

  public get postPlayTimeHasEnded(): boolean {
    return Date.now() >= this.ctfPostPlayEnd.getTime()
  }

  public get isDuringPlayTime(): boolean {
    return this.ctfHasStarted && !this.postPlayTimeHasEnded
  }
}

export const useMetadata = (): NonNullable<typeof metadata> => {
  const metadata = inject(COMPETITION_METADATA_INJECTION_TOKEN)
  if (!metadata) {
    throw new Error(`Could not inject ${COMPETITION_METADATA_INJECTION_TOKEN.description}`)
  }
  return metadata
}
