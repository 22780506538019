/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubmissionStatus = {
    Accepted: 1,
    Rejected: 2,
    Resubmitted: 3
} as const;
export type SubmissionStatus = typeof SubmissionStatus[keyof typeof SubmissionStatus];


export function instanceOfSubmissionStatus(value: any): boolean {
    for (const key in SubmissionStatus) {
        if (Object.prototype.hasOwnProperty.call(SubmissionStatus, key)) {
            if ((SubmissionStatus as Record<string, SubmissionStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SubmissionStatusFromJSON(json: any): SubmissionStatus {
    return SubmissionStatusFromJSONTyped(json, false);
}

export function SubmissionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmissionStatus {
    return json as SubmissionStatus;
}

export function SubmissionStatusToJSON(value?: SubmissionStatus | null): any {
    return value as any;
}

