// When downloading a file from a remote server which requires authentication using some token,
// the only way to do that seems to be using this hack, also employed by FileSaver.js ...
// https://github.com/eligrey/FileSaver.js/wiki/Saving-a-remote-file
// https://stackoverflow.com/a/19328891
export function saveAs(data: BlobPart, filename: string): void {
  const blob = new Blob([data], { type: 'application/octet-stream' })

  const link = document.createElement('a')
  link.download = filename
  link.href = URL.createObjectURL(blob)
  link.click()
  URL.revokeObjectURL(link.href)

  // We don't need to delete this link element here, since we didn't insert it in the DOM.
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement
}
