<template>
  <div class="text-center flex flex-wrap gap-3 grow justify-evenly items-center min-w-lg bg-accentBg">
    <InplaceReplaceTransition>
      <div v-if="progress && progress.isCompleted" class="flex justify-center items-center py-3">
        <p
          class="block mx-auto text-xl text-primary border-4 border-primary px-3 py-2 transform rotate-3 origin-center stamp
        rounded uppercase font-bold min-w-fit w-fit whitespace-nowrap"
        >
          {{ $t('challenge.flagSubmission.challengeCompleted') }}
        </p>
      </div>
      <form v-else class="grow mt-2 mb-8 relative" @submit.prevent="submitFlag">
        <div
          class="flex items-center border-b py-1"
          :class="{
            'border-primary': flagState === FlagState.Neutral || flagState === FlagState.Accepted,
            'border-orange-500': flagState === FlagState.Resubmitted,
            'border-error': flagState === FlagState.Rejected,
          }"
        >
          <FlagIcon class="w-6 h-6 mr-1 text-gray-400 flex-shrink-0" />
          <input
            v-model="flag"
            class="appearance-none bg-transparent border-none w-full mr-3 py-1 px-2 focus:outline-none text-white text-mono" type="text"
            :placeholder="metadata.flagPrefix + '{...}'" aria-label="Flag"
            @keydown="clearFlagSubmissionResult"
          >
          <div v-if="flagState" class="absolute top-[110%] left-0 text-sm">
            <p>
              <span v-if="flagState === FlagState.Accepted" class="text-primary inline-flex items-center">
                <CheckBadgeIcon class="w-[1.5em] h-[1.5em] mr-[0.5em]" />
                <span>{{ $t('challenge.flagSubmission.accepted') }}</span>
              </span>
              <span v-if="flagState === FlagState.Resubmitted" class="text-orange-500 inline-flex items-center">
                <ExclamationTriangleIcon class="w-[1.5em] h-[1.5em] mr-[0.5em]" />
                <span>{{ $t('challenge.flagSubmission.resubmitted') }}</span>
              </span>
              <span v-else-if="flagState === FlagState.Rejected" class="text-error inline-flex items-center">
                <ExclamationTriangleIcon class="w-[1.5em] h-[1.5em] mr-[0.5em]" />
                <span>{{ $t('challenge.flagSubmission.rejected') }}</span>
              </span>
            </p>
          </div>
          <button
            class="flex-shrink-0 bg-primaryBg enabled:hover:bg-primaryBgHover border-primary enabled:hover:border-primaryHighlight disabled:cursor-wait
        text-sm border-1 text-white py-1 px-2 rounded inline-flex items-center"
            type="button"
            :disabled="submittingFlag"
            @click="submitFlag"
          >
            <InplaceReplaceTransition>
              <span v-if="!submittingFlag">{{ $t('challenge.flagSubmission.submit') }}</span>
              <div v-else class="inline-flex items-center">
                <Spinner class="mr-3 h-6 w-6" /> {{ $t('challenge.flagSubmission.submitting') }}
              </div>
            </InplaceReplaceTransition>
          </button>
          <button
            class="flex-shrink-0 border-transparent border-4 text-primary enabled:hover:text-primaryHighlight text-sm py-1 px-2 rounded"
            type="button"
            :disabled="submittingFlag"
            @click="clearSubmitForm()"
          >
            {{ $t('challenge.flagSubmission.clear') }}
          </button>
        </div>
      </form>
    </InplaceReplaceTransition>
    <div v-if="progress" class="min-w-36">
      <div class="flex items-center justify-center" :class="{'mb-4': progress.totalFlags > 0}">
        <p class="sr-only">{{ progress.solvedFlags }} of {{ progress.totalFlags }} {{ progress.totalFlags === 1 ? 'flag' : 'flags' }}</p>
        <div v-for="n in progress.totalFlags" :key="n" aria-hidden="true">
          <InplaceReplaceTransition>
            <OutlineFlagIcon
              v-if="progress.solvedFlags < n" class="w-9 h-9 text-gray-300"
              :class="{'mr-1': n !== progress.totalFlags / 2 && progress.totalFlags <= 4}"
              :style="'transform: rotate(' + getFlagRotation(n, progress.totalFlags) + 'deg) translateY(' + getFlagYTranslation(n, progress.totalFlags) + 'px);'"
            />
            <FlagIcon
              v-else
              class="w-9 h-9 text-white"
              :class="{'mr-1': n !== progress.solvedFlags / 2 && progress.totalFlags <= 4}"
              :style="'transform: rotate(' + getFlagRotation(n, progress.totalFlags) + 'deg) translateY(' + getFlagYTranslation(n, progress.totalFlags) + 'px);'"
            />
          </InplaceReplaceTransition>
        </div>
      </div>
      <InplaceReplaceTransition>
        <p v-if="progress.totalFlags > 0" class="mt-1">
          <span v-if="progress.points > 0">{{ $t('challenge.flagSubmission.points', { n: progress.points, m: progress.pointsTotal }) }}</span>
          <span v-else>{{ $t('challenge.flagSubmission.maximumPoints', { n: progress.pointsTotal }) }}</span>
        </p>
        <p v-else>
          {{ $t('challenge.flagSubmission.noFlags') }}
        </p>
      </InplaceReplaceTransition>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { FlagIcon as OutlineFlagIcon, CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
  import { FlagIcon } from '@heroicons/vue/24/solid'
  import { ref, watch } from 'vue'

  import { HandlerChallengeScoreDto, SubmissionStatus } from '@/api'
  import InplaceReplaceTransition from '@/components/ui/InplaceReplaceTransition.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { useApi, useMetadata, usePeriodic } from '@/hooks'

  const props = defineProps({
    challengeSlug: {
      type: String,
      required: true,
    },
    categorySlug: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(['challenge-completed'])

  const { metadata } = useMetadata()

  enum FlagState {
    Accepted = 1,
    Resubmitted,
    Rejected,
    Neutral
  }

  const api = useApi()
  const flagState = ref(FlagState.Neutral)
  const flag = ref('')
  const progress = ref<null | HandlerChallengeScoreDto>()
  const submittingFlag = ref(false)

  const updateProgress = async () => {
    progress.value = await api.value.score.v1ChallengesSlugScoreGet({
      slug: props.challengeSlug,
      category: props.categorySlug,
    })
  }
  const periodic = usePeriodic(updateProgress, FLUGSICHERUNG_RELOAD_DATA_INTERVAL)
  watch(() => props.categorySlug, async () => {
    await periodic.trigger()
  })
  watch(() => props.challengeSlug, async () => {
    await periodic.trigger()
  })

  async function submitFlag() {
    submittingFlag.value = true
    try {
      const request = {
        slug: props.challengeSlug,
        category: props.categorySlug,
        flag: { flag: flag.value },
      }
      const flagResponse = await api.value.challenges.v1ChallengesSlugSolvePost(request)
      await periodic.trigger()

      // Stop the challenge if this was the last missing flag.
      if (flagResponse.status === SubmissionStatus.Accepted && progress.value?.isCompleted) {
        emit('challenge-completed')
      }

      switch (flagResponse.status) {
        case SubmissionStatus.Accepted:
          flagState.value = FlagState.Accepted
          flag.value = ''
          break
        case SubmissionStatus.Resubmitted:
          flagState.value = FlagState.Resubmitted
          flag.value = ''
          break
        default:
          flagState.value = FlagState.Rejected
      }
    } finally {
      submittingFlag.value = false
    }
  }

  const clearFlagSubmissionResult = () => {
    flagState.value = FlagState.Neutral
  }

  const clearSubmitForm = () => {
    flag.value = ''
    flagState.value = FlagState.Neutral
  }

  const FLAG_ROTATION_FACTOR = 20

  const getFlagRotation = (n: number, total: number): number => {
    if (total === 1 || total > 4) { // we do not support more than 4 flags in a rounded fashion (does not look good)
      return -10
    }
    return (n - 0.5 - (total / 2)) * FLAG_ROTATION_FACTOR
  }

  const getFlagYTranslation = (n: number, total: number): number => {
    if (total === 1 || total > 4) {
      return 0
    }

    const rotation = getFlagRotation(n, total)

    let result = Math.abs(rotation)

    // move flags to the left a bit up as they appear to be lower (due to their design)
    if (rotation < 0) {
      result += rotation * 0.2
    }

    // do not offset the topmost flag
    if (n - 0.5 !== total / 2) {
      result -= (FLAG_ROTATION_FACTOR / 2)
    }

    return result
  }
</script>

<style lang="scss" scoped>
  .stamp {
    -webkit-mask-image: url('@/images/stamp-mask.png');
    mask-image: url('@/images/stamp-mask.png');
    -webkit-mask-size: 655px 262px;
    mask-size: 655px 262px;
    mix-blend-mode: normal;
  }
</style>
