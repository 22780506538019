/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerSelectScoreGroupDto
 */
export interface HandlerSelectScoreGroupDto {
    /**
     * The slug of the score group to select.
     * @type {string}
     * @memberof HandlerSelectScoreGroupDto
     */
    scoreGroup: string;
}

/**
 * Check if a given object implements the HandlerSelectScoreGroupDto interface.
 */
export function instanceOfHandlerSelectScoreGroupDto(value: object): value is HandlerSelectScoreGroupDto {
    if (!('scoreGroup' in value) || value['scoreGroup'] === undefined) return false;
    return true;
}

export function HandlerSelectScoreGroupDtoFromJSON(json: any): HandlerSelectScoreGroupDto {
    return HandlerSelectScoreGroupDtoFromJSONTyped(json, false);
}

export function HandlerSelectScoreGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerSelectScoreGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'scoreGroup': json['scoreGroup'],
    };
}

export function HandlerSelectScoreGroupDtoToJSON(value?: HandlerSelectScoreGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scoreGroup': value['scoreGroup'],
    };
}

