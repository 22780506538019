import { HandlerErrorCode, HandlerErrorResponse, ResponseError, instanceOfHandlerErrorResponse } from '@/api'

export const isErrorResponse = (o: unknown): o is HandlerErrorResponse => {
  return typeof o === 'object' && !!o && instanceOfHandlerErrorResponse(o) && (o as {ok: boolean}).ok === false
}

export const extractErrorResponse = async (o: unknown): Promise<HandlerErrorResponse | null> => {
  if (!(o instanceof ResponseError)) {
    return null
  }
  const body: unknown = await o.response.clone().json()
  if (!isErrorResponse(body)) {
    return null
  }
  return body
}

export const isScoreGroupSelectionError = (err: HandlerErrorResponse | null): boolean => {
  return !!err && err.code === HandlerErrorCode.errorCodeNoScoreGroupSelected
}

export const isTimeRestrictedError = (err: HandlerErrorResponse | null): boolean => {
  return !!err && err.code === HandlerErrorCode.errorCodeAccessTimeRestricted
}

export const isMaximumNumberChallengeInstancesReachedError = (err: HandlerErrorResponse | null): boolean => {
  return !!err && err.code === HandlerErrorCode.errorCodeMaximumNumberChallengeInstancesReached
}

export const isCannotProlongChallengeInstanceError = (err: HandlerErrorResponse | null): boolean => {
  return !!err && err.code === HandlerErrorCode.errorCodeCannotProlongChallengeInstance
}
