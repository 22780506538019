<template>
  <header class="w-full sticky top-0 z-50 h-[var(--header-height)]">
    <div class="flex text-sm text-center items-center shadow-lg px-3 py-3 md:py-1 bg-accentBg">
      <RouterLink :to="{ name: 'home' }">
        <Logo class="w-16" />
      </RouterLink>
      <p class="text-left ml-4">
        Happy Hacking, {{ user.displayName ?? 'Uknown user' }}!
      </p>
      <div class="grow" />
      <div class="hidden md:block">
        <p>
          <template v-if="user.rank <= 10 && user.points > 0">
            {{ $t('navigation.notifications.ranking.rank', { n: user.rank, suffix: ordinalSuffix($i18n.locale, user.rank) }) }}<br>
          </template>
          <template v-if="user.points > 0">
            {{ $t('navigation.notifications.ranking.score', { n: user.points }) }}
          </template>
        </p>
      </div>
      <div class="hidden md:flex text-right items-center">
        <button
          class="inline-flex items-center hover:bg-accentHover py-2 px-3 m-2 rounded text-standardtext hover:text-standardtext"
          role="button" tabindex="0" @click="isDiscordModalOpen = true"
        >
          <ChatBubbleLeftRightIcon class="w-6 h-6 mr-2" />
          Discord
        </button>
        <button
          v-if="metadata.isInitialized && metadata.isDuringPlayTime && user.hasSelectedGroup"
          class="inline-flex items-center hover:bg-accentHover py-2 px-3 m-2 rounded"
          :class="{'text-error': !user?.vpnConnected, 'text-primary': user?.vpnConnected}" role="button" tabindex="0" @click="isVPNModalOpen = true"
        >
          <TvIcon v-if="user?.vpnConnected" class="w-6 h-6 mr-2" />
          <ExclamationTriangleIcon v-else class="w-6 h-6 mr-2" />
          {{ $t('navigation.buttons.vpn') }}
        </button>
        <LocaleSelector class="inline-flex items-center hover:bg-accentHover m-2 rounded text-standardtext hover:text-standardtext bg-transparent border-0" />
        <RouterLink
          class="inline-flex items-center hover:bg-accentHover py-2 px-3 m-2 rounded text-secondary hover:text-standardtext"
          role="button"
          tabindex="0"
          :to="{name: 'logout'}"
        >
          <ArrowRightStartOnRectangleIcon class="w-6 h-6 mr-2" />
          {{ $t('navigation.buttons.logout') }}
        </RouterLink>
      </div>
      <div class="md:hidden">
        <!-- The burger menu button when on small screens. -->
        <button
          class="hover:bg-accentHover py-2 px-3 rounded text-standardtext hover:text-standardtext"
          tabindex="0"
          role="button"
          @click.stop="handleMenuToggle"
        >
          <Bars3Icon
            class="w-6 h-6 transition-transform duration-300"
            :class="{
              'rotate-90': mobileMenuOpen
            }"
          />
        </button>
      </div>
    </div>
  </header>
  <VPNModal v-model="isVPNModalOpen" :vpnConnected="user?.vpnConnected" :vpnIP="user?.vpnIpAddress" />
  <DiscordModal v-model="isDiscordModalOpen" />
</template>

<script lang="ts" setup>
  import {
    ArrowRightStartOnRectangleIcon,
    Bars3Icon,
    ChatBubbleLeftRightIcon,
    ExclamationTriangleIcon,
    TvIcon,
  } from '@heroicons/vue/24/outline'
  import { ref } from 'vue'
  import { RouterLink } from 'vue-router'

  import Logo from './Logo.vue'

  import DiscordModal from '@/components/ui/DiscordModal.vue'
  import LocaleSelector from '@/components/ui/LocaleSelector.vue'
  import VPNModal from '@/components/ui/VPNModal.vue'
  import { useMetadata, useUser } from '@/hooks'
  import { ordinalSuffix } from '@/util/misc'

  const emits = defineEmits<{(e: 'toggleMobileMenu', open: boolean): void}>()
  const props = defineProps({
    mobileMenuOpen: {
      type: Boolean,
      required: true,
    },
  })

  const isVPNModalOpen = ref(false)
  const isDiscordModalOpen = ref(false)

  const { metadata } = useMetadata()
  const { user } = useUser()

  const handleMenuToggle = () => {
    const newState = !props.mobileMenuOpen
    emits('toggleMobileMenu', newState)
  }
</script>
