<template>
  <!-- eslint-disable-next-line vue/no-v-html - the input is sanitized by dompurify -->
  <div class="markdown-rendered-text" v-html="content" />
</template>

<script setup lang="ts">
  import DOMPurify from 'dompurify'
  import highlight from 'highlight.js'
  import markdownit from 'markdown-it'
  import markdownitAnchor from 'markdown-it-anchor'
  import { computed } from 'vue'

  import { KatexPlugin } from './katex-plugin'

  /**
   * Slugify converts a string to a slug.
   *
   * The following operations are done:
   *   * trim leading and trailing spaces
   *   * convert to lowercase
   *   * remove all non alphanumerical characters (umlauts are not preserved!)
   *   * replace spaces with hyphens
   *   * replace consecutive hyphens by a single one
   *
   * @param str The input parameter.
   * @returns The slugified string.
   */
  function slugify(str: string): string {
    str = str.trim()
    str = str.toLowerCase()
    str = str.replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
    return str
  }
  const props = defineProps({
    markdown: {
      type: String,
      required: true,
    },
  })

  const markdownConverter = markdownit(
    'default',
    {
      html: true,
      linkify: true,
      typographer: true,
      highlight: function (str, lang) {
        let highlightedCode = str
        if (lang && highlight.getLanguage(lang)) {
          try {
            // return highlight.highlight(str, { language: lang }).value
            highlightedCode = highlight.highlight(str, { language: lang, ignoreIllegals: true }).value
          } catch { /* Do nothing, just don't highlight it as below. */ }
        }

        return `<pre class="md-code-block"><code class="hljs">${highlightedCode}</code></pre>`
      },
    },
  )
    .use(KatexPlugin)
    .use(markdownitAnchor, { slugify })

  const content = computed(() => {
    const rawHtml = markdownConverter.render(props.markdown)
    return DOMPurify.sanitize(
      rawHtml,
      {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'id'],
      },
    )
  })
</script>

<style lang="scss" scoped>
  .markdown-rendered-text :deep() {
    .md-code-block {
      @apply my-0;
      &:not(:first-child) {
        @apply mt-4;
      }
      &:not(:last-child) {
        @apply mb-4;
      }
      code {
        @apply rounded-md;
      }
    }
    // This is a selection of themes that work okay-ish.
    // Didn't finde the perfect one, yet.
    // @import 'highlight.js/styles/base16/papercolor-light.min';
    // @import 'highlight.js/styles/base16/atelier-dune-light.min';
    // @import 'highlight.js/styles/obsidian.min';
    @import 'highlight.js/styles/vs2015.min';

    // Maths
    @import 'katex/dist/katex';

    // Styling all the other components in markdown.
    // Headings
    h1, h2, h3, h4, h5, h6 {
      @apply text-primary mb-2;
    }
    h1 {
      @apply text-5xl mt-6;
    }
    h2 {
      @apply text-4xl mt-5;
    }
    h3 {
      @apply text-3xl mt-4;
    }
    h4 {
      @apply text-2xl mt-3;
    }
    h5 {
      @apply text-xl mt-2;
    }
    h6 {
      @apply text-lg mt-1;
    }

    // Quotes
    blockquote {
      @apply border-l-4 border-white border-opacity-20 px-4 py-1 my-4 text-muted;
    }

    // Paragraphs
    p {
      @apply my-0;
      &:not(:first-child) {
        @apply mt-2;
      }
    }

    // Lists
    ul, ol {
      @apply my-0;
    }

    // Dividers
    hr {
      @apply my-0;
      &:not(:first-child) {
        @apply mt-6;
      }
      &:not(:last-child) {
        @apply mb-6;
      }
    }

    // Tables
    table {
      @apply my-0;
      &:not(:first-child) {
        @apply mt-4;
      }
      &:not(:last-child) {
        @apply mb-4;
      }
    }
    th, td {
      @apply border-solid border border-gray-400 px-3 py-2;
    }

    // Inline code
    code {
      @apply rounded-md bg-white bg-opacity-20 py-[calc(1.5rem/12)] px-[calc(4rem/12)];
    }
  }
</style>
