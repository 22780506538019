<template>
  <div class="h-full flex flex-col relative">
    <div class="grow w-full overflow-y-auto">
      <PageContentContainer>
        <template v-if="challenge && challengeMeta">
          <div class="flex flex-wrap gap-3 md:px-15 justify-center items-center">
            <div>
              <div class="flex justify-center">
                <InplaceReplaceTransition>
                  <button
                    v-if="user.maximumChallengeInstances && user.runningChallengeInstances.length >= user.maximumChallengeInstances &&
                      challengeMeta?.state === OrchestratorChallengeState.StateNotRunning"
                    :disabled="true"
                    class="start-stop-button bg-red-800 border-red-600 cursor-not-allowed"
                  >
                    <div class="inline-flex items-center">
                      <XMarkIcon class="w-6 h-6 mr-2" />
                      {{ $t('challenge.buttons.startingForbidden') }}
                    </div>
                  </button>
                  <div v-else-if="!processingChallengeState && challengeMeta?.state === OrchestratorChallengeState.StateRunning">
                    <button
                      class="start-stop-button bg-orange-800 hover:bg-orange-600 border-orange-600"
                      @click="stopChallenge"
                    >
                      <div class="inline-flex items-center">
                        <StopIcon class="w-6 h-6 mr-2" />
                        {{ $t('challenge.buttons.stop') }}
                      </div>
                    </button>
                    <button
                      class="start-stop-button bg-primaryBg hover:bg-primaryBgHover border-primary hover:border-primaryHighlight ml-5"
                      :class="{
                        'animate-shake': hasProlongError,
                      }"
                      :disabled="processingChallengeProlong"
                      @click="prolongChallenge"
                    >
                      <InplaceReplaceTransition>
                        <Spinner v-if="processingChallengeProlong" class="w-6 h-6 mr-2" />
                        <ForwardIcon v-else class="w-6 h-6 mr-2" />
                      </InplaceReplaceTransition>
                      {{ $t('challenge.buttons.extend') }}
                    </button>
                  </div>
                  <button
                    v-else-if="processingChallengeState && challengeMeta?.state === OrchestratorChallengeState.StateRunning ||
                      challengeMeta?.state === OrchestratorChallengeState.StateStopping"
                    :disabled="true"
                    class="start-stop-button bg-orange-800 border-orange-600 cursor-wait"
                  >
                    <div class="inline-flex items-center">
                      <Spinner class="-ml-1 mr-3 h-6 w-6" />
                      {{ $t('challenge.buttons.stopping') }}
                    </div>
                  </button>
                  <button
                    v-else
                    :disabled="processingChallengeState || challengeMeta?.state === OrchestratorChallengeState.StateStarting"
                    class="start-stop-button bg-primaryBg enabled:hover:bg-primaryBgHover border-primary enabled:hover:border-primaryHighlight disabled:cursor-wait"
                    @click="startChallenge"
                  >
                    <InplaceReplaceTransition>
                      <div v-if="!processingChallengeState && challengeMeta?.state !== OrchestratorChallengeState.StateStarting" class="inline-flex items-center">
                        <PlayIcon class="w-6 h-6 mr-2" />
                        {{ $t('challenge.buttons.start') }}
                      </div>
                      <div v-else class="inline-flex items-center">
                        <Spinner class="-ml-1 mr-3 h-6 w-6" />
                        {{ $t('challenge.buttons.starting') }}
                      </div>
                    </InplaceReplaceTransition>
                  </button>
                </InplaceReplaceTransition>
              </div>
              <InplaceReplaceTransition>
                <p v-if="!processingChallengeState && challengeMeta?.state === OrchestratorChallengeState.StateStarting" class="text-center text-sm pt-2 px-2">
                  <a class="text-muted hover:text-error" href="#" @click="stopChallenge">{{ $t('challenge.buttons.abortStart') }}</a>
                </p>
                <p v-else class="text-center text-sm text-muted pt-2 px-2">
                  {{
                    $t('challenge.notifications.usage', {
                      n: user.runningChallengeInstances.length,
                      m: user.maximumChallengeInstances ?? $t('challenge.notifications.usageUnlimited')
                    })
                  }}

                  <template
                    v-if="user.maximumChallengeInstances && user.runningChallengeInstances.length >= user.maximumChallengeInstances &&
                      challengeMeta?.state === OrchestratorChallengeState.StateNotRunning"
                  >
                    <br>
                    <strong>
                      {{ $t('challenge.notifications.tooManyChallengesDescription') }}
                    </strong>
                  </template>
                </p>
              </InplaceReplaceTransition>
              <InplaceReplaceTransition>
                <div
                  v-if="challengeMeta?.scheduledStopTime && challengeMeta?.state === OrchestratorChallengeState.StateRunning"
                  class="text-sm text-center text-muted pt-2 px-2"
                >
                  <InplaceReplaceTransition>
                    <i18n-t v-if="remainingMinutes && remainingMinutes > 0" tag="p" keypath="challenge.notifications.stoppedInNotification" scope="global">
                      <template v-slot:minutes>
                        <strong :class="{'text-red-600': remainingMinutes <= 5}">{{ remainingMinutes }}</strong>&nbsp;{{ $t('general.minute', remainingMinutes) }}
                      </template>
                    </i18n-t>
                    <i18n-t v-else tag="p" keypath="challenge.notifications.stoppedSoonNotification" scope="global">
                      <template v-slot:stoppedSoon>
                        <strong class="text-red-600">{{ $t('challenge.notifications.stoppedSoon') }}</strong>.
                      </template>
                    </i18n-t>
                  </InplaceReplaceTransition>
                </div>
              </InplaceReplaceTransition>
            </div>
          </div>
          <InplaceReplaceTransition>
            <div
              v-if="!user.vpnConnected && (processingChallengeState || challengeMeta?.state !== OrchestratorChallengeState.StateNotRunning)"
              class="bg-accentBg border-1 border-l-5 border-error rounded-md text-white px-3 py-3 my-5 flex items-center"
            >
              <div class="flex items-center w-full">
                <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none">
                  <ExclamationTriangleIcon />
                </div>
                <div class="flex-1">
                  <p>
                    <strong>{{ $t('challenge.vpn.notConnected') }}</strong><br>
                    {{ $t('challenge.vpn.notConnectedDescription') }}
                    <i18n-t tag="span" keypath="challenge.vpn.getVPNConfig" scope="global">
                      <template v-slot:modalLink>
                        <a href="#" @click.prevent="vpnModalOpen = true">{{ $t('challenge.vpn.here') }}</a>
                      </template>
                    </i18n-t>
                  </p>
                </div>
              </div>
            </div>
          </InplaceReplaceTransition>

          <InplaceReplaceTransition>
            <ChallengeEndpoints v-if="challengeMeta?.exposedEndpoints.length > 0 && !processingChallengeState" :instance="challengeMeta" />
          </InplaceReplaceTransition>

          <MarkdownContent v-if="challenge" :markdown="challenge.description" />
        </template>
        <VPNModal v-model="vpnModalOpen" :vpn-connected="user.vpnConnected" :vpn-i-p="user.vpnIpAddress" />
      </PageContentContainer>
    </div>
    <FlagSubmission
      class="w-full px-2 md:px-4 lg:px-8 py-2 h-fit grow-0"
      :challenge-slug="challengeSlug"
      :category-slug="categorySlug"
      @challenge-completed="stopChallenge"
    />
  </div>
</template>

<script lang="ts" setup>
  import {
    ExclamationTriangleIcon,
    PlayIcon,
    StopIcon,
    XMarkIcon,
    ForwardIcon,
  } from '@heroicons/vue/24/solid'
  import { onMounted, ref, computed, watch } from 'vue'
  import { useRoute } from 'vue-router'

  import {
    HandlerChallengeInstanceDto,
    HandlerSingleChallengeResponse,
    OrchestratorChallengeState,
  } from '@/api'
  import ChallengeEndpoints from '@/components/ChallengeEndpoints.vue'
  import FlagSubmission from '@/components/FlagSubmission.vue'
  import MarkdownContent from '@/components/MarkdownContent'
  import PageContentContainer from '@/components/PageContentContainer.vue'
  import InplaceReplaceTransition from '@/components/ui/InplaceReplaceTransition.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import VPNModal from '@/components/ui/VPNModal.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { useApi, useUser, usePeriodic } from '@/hooks'
  import {
    extractErrorResponse,
    isCannotProlongChallengeInstanceError,
    isMaximumNumberChallengeInstancesReachedError,
    isTimeRestrictedError,
  } from '@/util/error'

  const route = useRoute()
  const challengeSlug = computed(() =>
    Array.isArray(route.params.slug) ? route.params.slug[0] : route.params.slug)
  const categorySlug = computed(() =>
    Array.isArray(route.params.category) ? route.params.category[0] : route.params.category)

  const { user, refetch: refetchUser } = useUser()
  const api = useApi()

  const challenge = ref<null | HandlerSingleChallengeResponse>()
  const challengeMeta = ref<null | HandlerChallengeInstanceDto>(null)

  const processingChallengeState = ref(false)
  const processingChallengeProlong = ref(false)
  const hasProlongError = ref(false)

  const vpnModalOpen = ref(false)

  const metaPeriodic = usePeriodic(updateChallengeMeta, FLUGSICHERUNG_RELOAD_DATA_INTERVAL)

  const remainingMinutes = computed(() => {
    if (challengeMeta.value) {
      return Math.ceil((Date.parse(challengeMeta.value.scheduledStopTime) - Date.now()) / 60000)
    } else {
      return undefined
    }
  })

  const loadChallenge = async () => {
    hasProlongError.value = false
    challenge.value = await api.value.challenges.v1ChallengesSlugGet({
      slug: challengeSlug.value,
      category: categorySlug.value,
    })
    metaPeriodic.trigger()
  }

  watch(() => route.params.slug, loadChallenge)
  watch(() => route.params.category, loadChallenge)

  onMounted(async () => {
    await loadChallenge()
  })

  async function updateChallengeMeta(): Promise<void> {
    try {
      const newData = await api.value.challenges.v1ChallengesSlugInstanceGet({
        slug: challengeSlug.value,
        category: categorySlug.value,
      })
      challengeMeta.value = newData
    } catch (ex) {
      const err = await extractErrorResponse(ex)
      if (err && isTimeRestrictedError(err)) {
        return
      }
      throw ex
    }
  }

  async function startChallenge() {
    processingChallengeState.value = true
    try {
      await api.value.challenges.v1ChallengesSlugInstancePut({
        slug: challengeSlug.value,
        category: categorySlug.value,
      })
    } catch (ex) {
      const err = await extractErrorResponse(ex)
      if (isMaximumNumberChallengeInstancesReachedError(err)) {
        // As we reload the metadata below on failure, the user will get feedback
        // that starting more challenges is prohibited.
        // At least when the fetching of user and metadata does not fail.
        return
      }
      throw ex
    } finally {
      await metaPeriodic.trigger()
      await refetchUser()
      processingChallengeState.value = false
    }
  }

  async function prolongChallenge() {
    processingChallengeProlong.value = true
    hasProlongError.value = false
    try {
      await api.value.challenges.v1ChallengesSlugInstanceProlongPost({
        slug: challengeSlug.value,
        category: categorySlug.value,
      })
    } catch (ex) {
      const err = await extractErrorResponse(ex)
      if (isCannotProlongChallengeInstanceError(err)) {
        hasProlongError.value = true
        return
      }
      throw ex
    } finally {
      await metaPeriodic.trigger()
      await refetchUser()
      processingChallengeProlong.value = false
    }
  }

  async function stopChallenge() {
    processingChallengeState.value = true
    try {
      await api.value.challenges.v1ChallengesSlugInstanceDelete({
        slug: challengeSlug.value,
        category: categorySlug.value,
      })
      await metaPeriodic.trigger()
    } finally {
      processingChallengeState.value = false
    }
  }
</script>

<style scoped lang="scss">
  .start-stop-button {
    @apply text-white font-bold py-2 px-4 border rounded inline-flex items-center;
  }
</style>
