/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserFlugzeugStatus = {
    Provisioning: 'provisioning',
    Starting: 'starting',
    Running: 'running',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Error: 'error'
} as const;
export type UserFlugzeugStatus = typeof UserFlugzeugStatus[keyof typeof UserFlugzeugStatus];


export function instanceOfUserFlugzeugStatus(value: any): boolean {
    for (const key in UserFlugzeugStatus) {
        if (Object.prototype.hasOwnProperty.call(UserFlugzeugStatus, key)) {
            if ((UserFlugzeugStatus as Record<string, UserFlugzeugStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UserFlugzeugStatusFromJSON(json: any): UserFlugzeugStatus {
    return UserFlugzeugStatusFromJSONTyped(json, false);
}

export function UserFlugzeugStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFlugzeugStatus {
    return json as UserFlugzeugStatus;
}

export function UserFlugzeugStatusToJSON(value?: UserFlugzeugStatus | null): any {
    return value as any;
}

