/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The response containing whether the flugzeug is enabled.
 * @export
 * @interface HandlerFlugzeugEnabledResponse
 */
export interface HandlerFlugzeugEnabledResponse {
    /**
     * Whether the flugzeug is enabled.
     * @type {boolean}
     * @memberof HandlerFlugzeugEnabledResponse
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the HandlerFlugzeugEnabledResponse interface.
 */
export function instanceOfHandlerFlugzeugEnabledResponse(value: object): value is HandlerFlugzeugEnabledResponse {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function HandlerFlugzeugEnabledResponseFromJSON(json: any): HandlerFlugzeugEnabledResponse {
    return HandlerFlugzeugEnabledResponseFromJSONTyped(json, false);
}

export function HandlerFlugzeugEnabledResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerFlugzeugEnabledResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

export function HandlerFlugzeugEnabledResponseToJSON(value?: HandlerFlugzeugEnabledResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'enabled': value['enabled'],
    };
}

