/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HandlerErrorResponse,
  HandlerHealthResponse,
  HandlerMetadataResponse,
} from '../models/index';
import {
    HandlerErrorResponseFromJSON,
    HandlerErrorResponseToJSON,
    HandlerHealthResponseFromJSON,
    HandlerHealthResponseToJSON,
    HandlerMetadataResponseFromJSON,
    HandlerMetadataResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get API health.
     */
    async v1HealthGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerHealthResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerHealthResponseFromJSON(jsonValue));
    }

    /**
     * Get API health.
     */
    async v1HealthGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerHealthResponse> {
        const response = await this.v1HealthGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get CTF metadata.
     */
    async v1MetadataGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerMetadataResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/metadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerMetadataResponseFromJSON(jsonValue));
    }

    /**
     * Get CTF metadata.
     */
    async v1MetadataGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerMetadataResponse> {
        const response = await this.v1MetadataGetRaw(initOverrides);
        return await response.value();
    }

}
