/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HandlerErrorResponse,
  HandlerFlugzeugEnabledResponse,
  HandlerFlugzeugResponse,
  HandlerScoreGroupDto,
  HandlerSelectScoreGroupDto,
  HandlerUserResponse,
} from '../models/index';
import {
    HandlerErrorResponseFromJSON,
    HandlerErrorResponseToJSON,
    HandlerFlugzeugEnabledResponseFromJSON,
    HandlerFlugzeugEnabledResponseToJSON,
    HandlerFlugzeugResponseFromJSON,
    HandlerFlugzeugResponseToJSON,
    HandlerScoreGroupDtoFromJSON,
    HandlerScoreGroupDtoToJSON,
    HandlerSelectScoreGroupDtoFromJSON,
    HandlerSelectScoreGroupDtoToJSON,
    HandlerUserResponseFromJSON,
    HandlerUserResponseToJSON,
} from '../models/index';

export interface V1UserScoregroupPutRequest {
    scoreGroup: HandlerSelectScoreGroupDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Return available score groups for selection.
     */
    async v1ScoregroupsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HandlerScoreGroupDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/scoregroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HandlerScoreGroupDtoFromJSON));
    }

    /**
     * Return available score groups for selection.
     */
    async v1ScoregroupsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HandlerScoreGroupDto>> {
        const response = await this.v1ScoregroupsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete the user\'s flugzeug
     */
    async v1UserFlugzeugDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the user\'s flugzeug
     */
    async v1UserFlugzeugDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserFlugzeugDeleteRaw(initOverrides);
    }

    /**
     * Get information about whether the flugzeug is enabled.
     */
    async v1UserFlugzeugEnabledGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerFlugzeugEnabledResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug/enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerFlugzeugEnabledResponseFromJSON(jsonValue));
    }

    /**
     * Get information about whether the flugzeug is enabled.
     */
    async v1UserFlugzeugEnabledGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerFlugzeugEnabledResponse> {
        const response = await this.v1UserFlugzeugEnabledGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get information about user flugzeug VM.
     */
    async v1UserFlugzeugGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerFlugzeugResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerFlugzeugResponseFromJSON(jsonValue));
    }

    /**
     * Get information about user flugzeug VM.
     */
    async v1UserFlugzeugGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerFlugzeugResponse> {
        const response = await this.v1UserFlugzeugGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new flugzeug for the user.
     */
    async v1UserFlugzeugPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new flugzeug for the user.
     */
    async v1UserFlugzeugPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserFlugzeugPostRaw(initOverrides);
    }

    /**
     * Prolong the user\'s flugzeug
     */
    async v1UserFlugzeugProlongPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug/prolong`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Prolong the user\'s flugzeug
     */
    async v1UserFlugzeugProlongPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserFlugzeugProlongPostRaw(initOverrides);
    }

    /**
     * Start the user\'s flugzeug
     */
    async v1UserFlugzeugStartPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug/start`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start the user\'s flugzeug
     */
    async v1UserFlugzeugStartPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserFlugzeugStartPutRaw(initOverrides);
    }

    /**
     * Stop the user\'s flugzeug
     */
    async v1UserFlugzeugStopPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/flugzeug/stop`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stop the user\'s flugzeug
     */
    async v1UserFlugzeugStopPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserFlugzeugStopPutRaw(initOverrides);
    }

    /**
     * Get user information.
     */
    async v1UserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HandlerUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HandlerUserResponseFromJSON(jsonValue));
    }

    /**
     * Get user information.
     */
    async v1UserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HandlerUserResponse> {
        const response = await this.v1UserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the score group for the current user.
     */
    async v1UserScoregroupPutRaw(requestParameters: V1UserScoregroupPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['scoreGroup'] == null) {
            throw new runtime.RequiredError(
                'scoreGroup',
                'Required parameter "scoreGroup" was null or undefined when calling v1UserScoregroupPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user/scoregroup`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HandlerSelectScoreGroupDtoToJSON(requestParameters['scoreGroup']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set the score group for the current user.
     */
    async v1UserScoregroupPut(requestParameters: V1UserScoregroupPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1UserScoregroupPutRaw(requestParameters, initOverrides);
    }

    /**
     * Get user vpn configuration.
     */
    async v1UserVpnConfigGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/vpn/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get user vpn configuration.
     */
    async v1UserVpnConfigGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.v1UserVpnConfigGetRaw(initOverrides);
        return await response.value();
    }

}
