import * as Sentry from '@sentry/vue'
import { UserManager, InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts'
import { NavigationGuard } from 'vue-router'

export type User = {
  id: string;
  displayName: string;
  vpnConnected: boolean;
  vpnIpAddress: string;
  rank: number;
  points: number;
}

const loginRedirectURL = () => `${window.location.protocol}//${window.location.host}/auth/redirect`
const logoutRedirectURL = () => `${window.location.protocol}//${window.location.host}/`

type OnTokenChangeCallback = (newToken: string | null) => void

export class OidcAuthService {
  private userManager: UserManager
  private _token: string | null
  private tokenChangeListeners: OnTokenChangeCallback[]

  private constructor() {
    const clientId = (import.meta.env.VITE_OIDC_CLIENT_ID || window.OIDC_CLIENT_ID)?.trim()
    if (!clientId) {
      throw new Error('Please set OIDC_CLIENT_ID.')
    }
    const issuer = (import.meta.env.VITE_OIDC_ISSUER_URL || window.OIDC_ISSUER_URL)?.trim()
    if (!issuer) {
      throw new Error('Please set OIDC_ISSUER_URL.')
    }

    this.userManager = new UserManager({
      client_id: clientId,
      authority: issuer,
      redirect_uri: loginRedirectURL(),
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
      post_logout_redirect_uri: logoutRedirectURL(),
    })

    this.userManager.events.addUserLoaded(async (newUser) => {
      await this.userManager.storeUser(newUser)
      this.accessToken = newUser.access_token
    })

    this.userManager.events.addSilentRenewError(async (error) => {
      Sentry.captureMessage('Silent renew failed', {
        level: 'warning',
        extra: {
          error,
        },
      })
      await this.userManager.signoutRedirect()
      await this.reset()
    })

    this._token = null
    this.tokenChangeListeners = []
  }

  // Singleton
  private static instance = new OidcAuthService()
  public static default(): OidcAuthService {
    return this.instance
  }

  async reset(): Promise<void> {
    this.accessToken = null
    return this.userManager.removeUser()
  }

  async login(): Promise<void> {
    try {
      await this.userManager.signinRedirect()
    } catch (ex) {
      throw new Error('login failed', { cause: ex })
    }
  }

  async logout(): Promise<void> {
    try {
      await this.userManager.signoutRedirect()
    } catch (ex) {
      throw new Error('handling logout', { cause: ex })
    }
  }

  async handleOidcRedirect(): Promise<void> {
    try {
      const user = await this.userManager.signinRedirectCallback()
      this.accessToken = user.access_token
    } catch (ex) {
      throw new Error('handling redirect', { cause: ex })
    }
  }

  public get accessToken(): string | null {
    return this._token
  }

  private set accessToken(token: string | null) {
    this._token = token
    // Notify the listeners.
    for (const listener of this.tokenChangeListeners) {
      listener(token)
    }
  }

  public addOnTokenChangedListener(cb: OnTokenChangeCallback): void {
    this.tokenChangeListeners.push(cb)
    cb(this.accessToken)
  }

  public removeOnTokenChangedListener(cb: OnTokenChangeCallback): void {
    const idx = this.tokenChangeListeners.indexOf(cb)
    if (idx !== -1) {
      this.tokenChangeListeners.splice(idx, 1)
    }
  }
}

export const authGuard: NavigationGuard = (to) => {
  const isAuthenticated = !!OidcAuthService.default().accessToken
  if (!isAuthenticated && to.name !== 'afterLoginRedirect' && to.name !== 'login') {
    return { name: 'login' }
  }
}
