<template>
  <div
    v-if="metadata.isInitialized && user.isInitialized"
    class="relative container mx-auto max-w-9xl px-2 md:px-4 lg:px-8 grow py-4"
  >
    <slot />
  </div>
  <div v-else class="w-full h-full flex">
    <Spinner class="w-6 h-6 m-auto" />
  </div>
</template>

<script lang="ts" setup>
  import Spinner from './ui/Spinner.vue'

  import { useMetadata, useUser } from '@/hooks'

  const { metadata } = useMetadata()
  const { user } = useUser()
</script>
