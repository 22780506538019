import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'

import ChallengeComponent from '@/components/Challenge.vue'
import FlugzeugComponent from '@/components/Flugzeug.vue'
import ScoreboardComponent from '@/components/Scoreboard.vue'
import SolvesCalendarComponent from '@/components/SolvesCalendar.vue'
import DummyComponent from '@/components/auth/Dummy.vue'
import HomeComponent from '@/components/home'
import { setupI18n } from '@/util/i18n'
import { authGuard } from '@/util/oidc'
import { initSentry } from '@/util/sentry'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      children: [
        {
          path: '',
          name: 'home',
          component: HomeComponent,
        },
        {
          path: 'scoreboard',
          name: 'scoreboard',
          component: ScoreboardComponent,
        },
        {
          path: 'solves-calendar',
          name: 'solves-calendar',
          component: SolvesCalendarComponent,
        },
        {
          path: 'flugzeug',
          name: 'flugzeug',
          component: FlugzeugComponent,
        },
        {
          path: 'challenge/:category/:slug',
          name: 'challenge',
          component: ChallengeComponent,
        },
        {
          path: 'auth/',
          // All auth routes are handled by the UserProviderComponent.
          // Just need an empty component for vue-router to work...
          children: [
            {
              name: 'login',
              path: 'login',
              component: DummyComponent,
            },
            {
              name: 'afterLoginRedirect',
              path: 'redirect',
              component: DummyComponent,
            },
            {
              name: 'logout',
              path: 'logout',
              component: DummyComponent,
            },
          ],
        },
      ],
    },
  ],
})

const app = createApp(App)

// Enforce authentication.
router.beforeEach(authGuard)

app.use(router)
initSentry(app)

// Enable i18n
const i18n = setupI18n({})
app.use(i18n)

app.mount('#app')
