<template>
  <PageContentContainer class="grow-0">
    <div class="flex flex-col">
      <div class="my-4 grow-0">
        <div class="text-center">
          <Button
            v-if="showStart"
            :loading="starting"
            :icon="playIcon"
            :disabled="starting"
            :text="$t('flugzeug.buttons.start')"
            :text-loading="$t('flugzeug.buttons.starting')"
            class="text-white bg-primaryBg enabled:hover:bg-primaryBgHover border-primary enabled:hover:border-primaryHighlight disabled:cursor-wait"
            @click="startFlugzeug"
          />

          <Button
            v-if="running && !stopping"
            :icon="forwardIcon"
            :loading="processingProlong"
            :text="$t('flugzeug.buttons.extend')"
            class="text-white bg-primaryBg hover:bg-primaryBgHover border-primary hover:border-primaryHighlight"
            :class="{
              'animate-shake': hasProlongError,
            }"
            @click="prolongFlugzeug"
          />

          <Button
            v-if="showStop"
            :loading="stopping"
            :icon="stopIcon"
            :text="$t('flugzeug.buttons.stop')"
            :text-loading="$t('flugzeug.buttons.stopping')"
            :disabled="!running || stopping"
            class="text-white bg-orange-800 enabled:hover:bg-orange-600 border-orange-500 disabled:cursor-wait"
            @click="stopFlugzeug"
          />

          <Button
            v-if="flugzeug"
            :loading="processingDelete"
            :icon="trashIcon"
            :text="$t('flugzeug.buttons.reset')"
            :text-loading="$t('flugzeug.buttons.resetting')"
            class="text-white bg-red-800 hover:bg-red-600 border-red-500"
            @click="deleteFlugzeug"
          />
        </div>
        <div v-if="running" class="text-center text-sm text-muted">
          <span v-if="remainingMinutes !== undefined && remainingMinutes > 0">
            <i18n-t tag="span" scope="global" keypath="flugzeug.notifications.stoppedIn">
              <template v-slot:minutes>
                <b>{{ remainingMinutes }} {{ $t('general.minute', remainingMinutes) }}</b>
              </template>
            </i18n-t>
          </span>
          <span v-else>
            {{ $t('flugzeug.notifications.stoppedSoon') }}
          </span>
        </div>
      </div>
      <div v-if="!(running || starting || stopping)" class="grow">
        <h1 class="font-bold text-xl mb-4">
          {{ $t('flugzeug.explanatoryTexts.heading') }}
        </h1>

        <i18n-t scope="global" keypath="flugzeug.explanatoryTexts.introduction.text" tag="p">
          <template v-slot:vmlink>
            <a href="https://en.wikipedia.org/wiki/Virtual_machine">{{ $t('flugzeug.explanatoryTexts.introduction.vmLink') }}</a>
          </template>
          <template v-slot:br>
            <br>
          </template>
          <template v-slot:vpn>
            <b>{{ $t('flugzeug.explanatoryTexts.introduction.vpn') }}</b>
          </template>
          <template v-slot:browser>
            <b>{{ $t('flugzeug.explanatoryTexts.introduction.browser') }}</b>
          </template>
          <br>
        </i18n-t>

        <h2 class="my-4 text-xl font-bold">{{ $t('flugzeug.explanatoryTexts.whenToUse.heading') }}</h2>

        <p>{{ $t('flugzeug.explanatoryTexts.whenToUse.intro') }}</p>

        <ul>
          <li>{{ $t('flugzeug.explanatoryTexts.whenToUse.startQuickly') }}</li>
          <li>{{ $t('flugzeug.explanatoryTexts.whenToUse.dontInstall') }}</li>
          <li>{{ $t('flugzeug.explanatoryTexts.whenToUse.dontClutter') }}</li>
        </ul>

        <h2 class="my-4 text-xl font-bold">{{ $t('flugzeug.explanatoryTexts.whenNotToUse.heading') }}</h2>

        <i18n-t tag="p" scope="global" keypath="flugzeug.explanatoryTexts.whenNotToUse.intro">
          <template v-slot:kalilink>
            <a href="https://www.kali.org/get-kali/#kali-virtual-machines" target="_blank">Kali Linux</a>
          </template>
        </i18n-t>

        <ul>
          <li>{{ $t('flugzeug.explanatoryTexts.whenNotToUse.mobileData') }}</li>
          <li>{{ $t('flugzeug.explanatoryTexts.whenNotToUse.developmentEnvironment') }}</li>
          <li>{{ $t('flugzeug.explanatoryTexts.whenNotToUse.performance') }}</li>
        </ul>

        <h2 class="text-xl font-bold my-4">{{ $t('flugzeug.explanatoryTexts.howToUse.heading') }}</h2>

        <table class="table-auto">
          <tr>
            <td class="w-24">
              <span class="text-primary mr-2 inline-flex items-center"><PlayIcon class="w-6 h-6 inline mr-1" />{{ $t('flugzeug.buttons.start') }}</span>
            </td>
            <td>
              {{ $t('flugzeug.explanatoryTexts.howToUse.start') }}
            </td>
          </tr>
          <tr>
            <td class="w-24">
              <span class="text-orange-600 mr-2 inline-flex items-center"><StopIcon class="w-6 h-6 inline mr-1" />{{ $t('flugzeug.buttons.stop') }}</span>
            </td>
            <i18n-t tag="td" scope="global" keypath="flugzeug.explanatoryTexts.howToUse.stop">
              <template v-slot:home>
                <code>/home</code>
              </template>
            </i18n-t>
          </tr>
          <tr>
            <td class="w-24">
              <span class="text-primary mr-2 inline-flex items-center"><ForwardIcon class="w-6 h-6 inline mr-1" />{{ $t('flugzeug.buttons.extend') }}</span>
            </td>
            <td>
              {{ $t('flugzeug.explanatoryTexts.howToUse.extend') }}
            </td>
          </tr>
          <tr>
            <td class="w-24">
              <span class="text-red-600 mr-2 inline-flex items-center"><TrashIcon class="w-6 h-6 inline mr-1" />{{ $t('flugzeug.buttons.reset') }}</span>
            </td>
            <i18n-t tag="td" scope="global" keypath="flugzeug.explanatoryTexts.howToUse.reset">
              <template v-slot:home>
                <code>/home</code>
              </template>
            </i18n-t>
          </tr>
        </table>

        <h2 class="my-4 text-xl font-bold">{{ $t('flugzeug.explanatoryTexts.persistence.heading') }}</h2>

        <ul>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.persistence.homeDirectory">
            <template v-slot:home>
              <code>/home</code>
            </template>
          </i18n-t>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.persistence.stopped">
            <template v-slot:stopped>
              <b class="text-orange-600">{{ $t('flugzeug.explanatoryTexts.persistence.stoppedEmphasis') }}</b>
            </template>
            <template v-slot:description>
              <i18n-t tag="b" scope="global" keypath="flugzeug.explanatoryTexts.persistence.stoppedDescription">
                <template v-slot:home>
                  <code>/home</code>
                </template>
              </i18n-t>
            </template>
          </i18n-t>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.persistence.reset">
            <template v-slot:reset>
              <b class="text-red-600">{{ $t('flugzeug.buttons.reset') }}</b>
            </template>
            <template v-slot:home>
              <code>/home</code>
            </template>
          </i18n-t>
        </ul>

        <h3 class="text-lg font-bold my-4">{{ $t('flugzeug.explanatoryTexts.vncFeatures.heading') }}</h3>

        <p>{{ $t('flugzeug.explanatoryTexts.vncFeatures.intro') }}</p>

        <ul>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.vncFeatures.clipboardDescription">
            <template v-slot:clipboard>
              <b>{{ $t('flugzeug.explanatoryTexts.vncFeatures.clipboard') }}</b>
            </template>
            <template v-slot:browser>
              <b>{{ $t('flugzeug.explanatoryTexts.vncFeatures.browser') }}</b>
            </template>
          </i18n-t>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.vncFeatures.fullscreenDescription">
            <template v-slot:fullscreen>
              <b>{{ $t('flugzeug.explanatoryTexts.vncFeatures.fullscreen') }}</b>
            </template>
          </i18n-t>
          <i18n-t tag="li" scope="global" keypath="flugzeug.explanatoryTexts.vncFeatures.specialKeysDescription">
            <template v-slot:specialKeys>
              <b>{{ $t('flugzeug.explanatoryTexts.vncFeatures.specialKeys') }}</b>
            </template>
            <template v-slot:ctrl>
              <code>{{ $t('flugzeug.explanatoryTexts.vncFeatures.ctrl') }}</code>
            </template>
            <template v-slot:alt>
              <code>Alt</code>
            </template>
            <template v-slot:keys>
              <b>Keys</b>
            </template>
          </i18n-t>
        </ul>
      </div>
    </div>
  </PageContentContainer>
  <div class="grow w-full relative">
    <iframe
      v-if="running"
      ref="flugzeugFrame"
      allowfullscreen
      class="w-full h-full"
      :src="iframeSrc"
      :allow="`clipboard-read; clipboard-write; self; ${flugzeug?.url}`"
    />
    <div
      v-else-if="starting || stopping"
      id="overlay"
      class="w-full h-full flex justify-center items-center flex-col absolute top-0 left-0"
    >
      <Spinner class="w-10 h-10 mb-2" />
      <div>
        {{ loadingLabel }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    ForwardIcon,
    PlayIcon,
    StopIcon,
    TrashIcon,
  } from '@heroicons/vue/24/solid'
  import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
  import { useI18n } from 'vue-i18n'

  import Button from './ui/Button.vue'
  import Spinner from './ui/Spinner.vue'

  import { HandlerFlugzeugResponse, ResponseError, UserFlugzeugStatus } from '@/api'
  import PageContentContainer from '@/components/PageContentContainer.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { useApi, usePeriodic } from '@/hooks'

  const i18n = useI18n()

  const KASM_FULLSCREEN_ACTION = 'fullscreen'

  type KasmEvent = {
    action: string,
  }

  const forwardIcon = shallowRef({
    render: ForwardIcon,
  })
  const playIcon = shallowRef({
    render: PlayIcon,
  })
  const stopIcon = shallowRef({
    render: StopIcon,
  })
  const trashIcon = shallowRef({
    render: TrashIcon,
  })

  const PHRASE_CYCLE = 3

  const TAKING_LONG_PHRASE = computed(() => i18n.t('flugzeug.waitingTexts.takingLongText'))
  const PROVISIONING_PHRASE = computed(() => i18n.t('flugzeug.waitingTexts.provisioningText'))

  // Starting the flugzeug
  const STARTING_PHRASES = computed(() => [
    i18n.t('flugzeug.waitingTexts.startingTexts.t1'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t2'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t3'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t4'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t5'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t6'),
    i18n.t('flugzeug.waitingTexts.startingTexts.t7'),
  ])

  // Stopping the flugzeug
  const STOPPING_PHRASES = computed(() => [
    i18n.t('flugzeug.waitingTexts.stoppingTexts.t1'),
    i18n.t('flugzeug.waitingTexts.stoppingTexts.t2'),
    i18n.t('flugzeug.waitingTexts.stoppingTexts.t3'),
    i18n.t('flugzeug.waitingTexts.stoppingTexts.t4'),
  ])

  const flugzeug = ref<HandlerFlugzeugResponse>()
  const api = useApi()

  const processingStart = ref(false)
  const processingProlong = ref(false)
  const processingStop = ref(false)
  const processingDelete = ref(false)

  const hasProlongError = ref(false)
  const loadingLabel = ref('')
  let loadingNumber = 0

  const flugzeugFrame = ref<HTMLIFrameElement | null>(null)

  const showStart = computed(() =>
    !flugzeug.value ||
    flugzeug.value?.status === UserFlugzeugStatus.Stopped ||
    starting.value,
  )
  const showStop = computed(() =>
    flugzeug.value &&
    (flugzeug.value?.status === UserFlugzeugStatus.Running || flugzeug.value?.status === UserFlugzeugStatus.Stopping),
  )

  const starting = computed(() =>
    (flugzeug.value &&
      (flugzeug.value.status === UserFlugzeugStatus.Starting ||
        flugzeug.value.status === UserFlugzeugStatus.Provisioning
      )
    ) || processingStart.value,
  )
  const running = computed(() =>
    flugzeug.value &&
    flugzeug.value.status === UserFlugzeugStatus.Running,
  )
  const stopping = computed(() =>
    (flugzeug.value && flugzeug.value.status === UserFlugzeugStatus.Stopping) ||
    processingStop.value,
  )

  const iframeSrc = computed(() => {
    if (!flugzeug.value) return ''

    const url = new URL(flugzeug.value?.url)
    const websocketPath = url.pathname.replace(/^\/+/, '') + '/websockify'
    url.searchParams.set('resize', 'remote')
    url.searchParams.set('show_control_bar', 'true')
    url.searchParams.set('clipboard_seamless', 'true')
    url.searchParams.set('path', websocketPath)
    if (!url.pathname.endsWith('/')) {
      url.pathname = url.pathname + '/'
    }

    return url.toString()
  })

  const remainingMinutes = ref<number | undefined>()

  const periodic = usePeriodic(updateFlugzeug, FLUGSICHERUNG_RELOAD_DATA_INTERVAL)

  // The window addEventListener and removeEventListener functions take functions returning void
  // as an argument. The linter doesn't like that we pass async functions (which return a Promise).
  // Hence, we have this sync wrapper to handle this.
  const syncHandleFullscreen = (event: MessageEvent) => { handleFullscreen(event) }

  onMounted(async () => {
    await periodic.trigger()

    window.addEventListener(
      'message',
      syncHandleFullscreen,
    )
  })

  onUnmounted(() => {
    window.removeEventListener('message', syncHandleFullscreen)
  })

  async function updateFlugzeug() {
    try {
      const response = await api.value.users.v1UserFlugzeugGet()

      flugzeug.value = response
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 404) {
          flugzeug.value = undefined
          return
        }
      }

      // rethrow for Sentry
      throw error
    }

    updateRemainingTime()
    updateLoadingSpinner()
  }

  function updateRemainingTime() {
    if (!flugzeug.value || !flugzeug.value.stopTimestamp) {
      remainingMinutes.value = undefined
      return
    }

    const stop = new Date(flugzeug.value.stopTimestamp)
    const now = new Date()

    const remainingSeconds = Math.max(0, Math.round((stop.getTime() - now.getTime()) / 1000))

    remainingMinutes.value = Math.floor(remainingSeconds / 60)
  }

  function updateLoadingSpinner() {
    if (!flugzeug.value || (
      flugzeug.value.status !== UserFlugzeugStatus.Starting &&
      flugzeug.value.status !== UserFlugzeugStatus.Stopping &&
      flugzeug.value.status !== UserFlugzeugStatus.Provisioning
    )
    ) {
      loadingNumber = 0
      loadingLabel.value = ''
      return
    }

    if (flugzeug.value.status === UserFlugzeugStatus.Provisioning) {
      loadingLabel.value = PROVISIONING_PHRASE.value
      return
    }

    const phrases = flugzeug.value.status === UserFlugzeugStatus.Starting ? STARTING_PHRASES : STOPPING_PHRASES
    const phraseIndex = Math.floor(loadingNumber / PHRASE_CYCLE)

    loadingLabel.value = phraseIndex >= phrases.value.length ? TAKING_LONG_PHRASE.value : phrases.value[phraseIndex]

    loadingNumber++
  }

  async function startFlugzeug() {
    processingStart.value = true
    // This is necessary because of the following sequence of events:
    // Start -> 4 x Extend -> hasProlongError = true -> Stop -> Start -> hasProlongError still true, Extend jiggles.
    // We can't reset it in updateFlugzeug, because then we would reset it instantly since we call that in prolongFlugzeug.
    hasProlongError.value = false

    if (flugzeug.value) {
      await api.value.users.v1UserFlugzeugStartPut()
    } else {
      await api.value.users.v1UserFlugzeugPost()
    }

    await periodic.trigger()

    processingStart.value = false
  }

  async function prolongFlugzeug() {
    hasProlongError.value = false
    processingProlong.value = true
    try {
      await api.value.users.v1UserFlugzeugProlongPost()
    } catch (error) {
      if (error instanceof ResponseError) {
        if (error.response.status === 400) {
          // already prolonged enough
          hasProlongError.value = true
          return
        }
      }

      // rethrow for Sentry
      throw error
    } finally {
      await periodic.trigger()

      processingProlong.value = false
    }
  }

  async function stopFlugzeug() {
    processingStop.value = true

    try {
      await api.value.users.v1UserFlugzeugStopPut()
      await periodic.trigger()
    } finally {
      processingStop.value = false
    }
  }

  async function deleteFlugzeug() {
    processingDelete.value = true

    try {
      await api.value.users.v1UserFlugzeugDelete()
      await periodic.trigger()
    } finally {
      processingDelete.value = false
    }
  }

  function isKasmEvent(data: object): data is KasmEvent {
    const event = data as KasmEvent
    return typeof event.action === 'string'
  }

  async function handleFullscreen(event: MessageEvent) {
    if (event.origin !== flugzeug.value?.url) {
      return
    }

    const data = event.data as object

    if (!isKasmEvent(data) || data.action !== KASM_FULLSCREEN_ACTION || !flugzeugFrame.value) {
      return
    }

    if (!document.fullscreenElement) {
      await flugzeugFrame.value.requestFullscreen()
    } else if (document.fullscreenElement === flugzeugFrame.value) {
      await document.exitFullscreen()
    }
  }
</script>

<style scoped>
#overlay {
  background-color: rgba(75,75,75,0.8);
}
</style>
