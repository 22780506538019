export function ordinalSuffix(locale: string, num: number): string {
  const ordinalRules = new Intl.PluralRules(locale, {
    type: 'ordinal',
  })
  const suffixes = {
    zero: '',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
    many: 'th',
  }
  return suffixes[ordinalRules.select(num)]
}
