/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubmissionStatus } from './SubmissionStatus';
import {
    SubmissionStatusFromJSON,
    SubmissionStatusFromJSONTyped,
    SubmissionStatusToJSON,
} from './SubmissionStatus';

/**
 * A flag submission response.
 * @export
 * @interface HandlerFlagSubmissionResponse
 */
export interface HandlerFlagSubmissionResponse {
    /**
     * Whether the flag was accepted.
     * @type {SubmissionStatus}
     * @memberof HandlerFlagSubmissionResponse
     */
    status: SubmissionStatus;
}

/**
 * Check if a given object implements the HandlerFlagSubmissionResponse interface.
 */
export function instanceOfHandlerFlagSubmissionResponse(value: object): value is HandlerFlagSubmissionResponse {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function HandlerFlagSubmissionResponseFromJSON(json: any): HandlerFlagSubmissionResponse {
    return HandlerFlagSubmissionResponseFromJSONTyped(json, false);
}

export function HandlerFlagSubmissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerFlagSubmissionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': SubmissionStatusFromJSON(json['status']),
    };
}

export function HandlerFlagSubmissionResponseToJSON(value?: HandlerFlagSubmissionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': SubmissionStatusToJSON(value['status']),
    };
}

