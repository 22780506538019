// Internationalization setup.
// Adapted from the example at https://github.com/intlify/vue-i18n-next/blob/master/examples/lazy-loading/vite/src/i18n.ts

import { isRef } from 'vue'
import { createI18n } from 'vue-i18n'
import type {
  I18n,
  I18nOptions,
  VueI18n,
  Composer,
  I18nMode,
} from 'vue-i18n'

import de from '../locales/de.json'
import en from '../locales/en.json'
type MessageSchema = typeof en
export const supportedLocales = ['en', 'de'] as const
export type SupportedLocale = typeof supportedLocales[number]
const defaultLocale: SupportedLocale = 'en'

const storageKey = 'lang'

function isComposer(
  instance: VueI18n | Composer,
  mode: I18nMode,
): instance is Composer {
  return mode === 'composition' && isRef(instance.locale)
}

export function getLocale(i18n: I18n): string {
  if (isComposer(i18n.global, i18n.mode)) {
    return i18n.global.locale.value
  } else {
    return i18n.global.locale
  }
}

export function setupI18n(
  options: I18nOptions<{ message: MessageSchema }, SupportedLocale> = {},
): typeof i18n {
  const browserLocale = getBrowserLocale()
  const initialLocale = getSavedLocale() ?? options.locale ?? browserLocale
  const enhancedOptions = {
    fallbackLocale: defaultLocale,
    ...options,
    legacy: false,
    locale: initialLocale,
    messages: {
      en,
      de,
    },
  } satisfies I18nOptions<{ message: MessageSchema }, SupportedLocale>
  const i18n = createI18n<false,
    typeof enhancedOptions, MessageSchema, Record<string, never>, Record<string, never>, SupportedLocale
  >(
    enhancedOptions,
  )
  setLocale(i18n, enhancedOptions.locale as SupportedLocale)
  return i18n
}

function setLocale(i18n: I18n, locale: SupportedLocale): void {
  saveLocale(locale)
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale
  } else {
    i18n.global.locale = locale
  }
}

function getSavedLocale(): SupportedLocale | null {
  const locale = localStorage.getItem(storageKey) as SupportedLocale
  if (!supportedLocales.includes(locale)) {
    return null
  }
  return locale
}

export function saveLocale(locale: string): void {
  localStorage.setItem(storageKey, locale)
}

function getBrowserLocale(): SupportedLocale {
  const browserSupportedLocales = [...(navigator.languages ?? []), navigator.language]
  for (const locale of browserSupportedLocales) {
    // Match, e.g., en-US and en-GB to en.
    const parsedLocale = new Intl.Locale(locale)
    const foundLocale = supportedLocales.find((l) => l === parsedLocale.baseName || l === parsedLocale.language)
    if (foundLocale) {
      return foundLocale
    }
  }
  return 'en'
}
