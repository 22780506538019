/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerScoreboardEntryDto } from './HandlerScoreboardEntryDto';
import {
    HandlerScoreboardEntryDtoFromJSON,
    HandlerScoreboardEntryDtoFromJSONTyped,
    HandlerScoreboardEntryDtoToJSON,
} from './HandlerScoreboardEntryDto';

/**
 * 
 * @export
 * @interface HandlerScoreboardDto
 */
export interface HandlerScoreboardDto {
    /**
     * The collection of scoreboard entries.
     * @type {Array<HandlerScoreboardEntryDto>}
     * @memberof HandlerScoreboardDto
     */
    entries: Array<HandlerScoreboardEntryDto>;
}

/**
 * Check if a given object implements the HandlerScoreboardDto interface.
 */
export function instanceOfHandlerScoreboardDto(value: object): value is HandlerScoreboardDto {
    if (!('entries' in value) || value['entries'] === undefined) return false;
    return true;
}

export function HandlerScoreboardDtoFromJSON(json: any): HandlerScoreboardDto {
    return HandlerScoreboardDtoFromJSONTyped(json, false);
}

export function HandlerScoreboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerScoreboardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(HandlerScoreboardEntryDtoFromJSON)),
    };
}

export function HandlerScoreboardDtoToJSON(value?: HandlerScoreboardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': ((value['entries'] as Array<any>).map(HandlerScoreboardEntryDtoToJSON)),
    };
}

