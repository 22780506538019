import { inject } from 'vue'

import { HandlerChallengeIdentifier } from '@/api'
import { AUTHENTICATION_INJECTION_TOKEN } from '@/injectionTokens'

export type User = {
  isInitialized: boolean,
  id: string;
  displayName: string;
  vpnConnected: boolean;
  vpnIpAddress: string;
  rank: number;
  points: number;
  numSolvedFlags: number;
  runningChallengeInstances: HandlerChallengeIdentifier[];
  completedChallenges: HandlerChallengeIdentifier[];
  maximumChallengeInstances: number | null;
  hasSelectedGroup: boolean;
  externalId: string;
}

const getInjection = () => {
  const authInjection = inject(AUTHENTICATION_INJECTION_TOKEN)
  if (!authInjection) {
    throw new Error(`Could not inject ${AUTHENTICATION_INJECTION_TOKEN.description}`)
  }
  return authInjection
}

/**
 * Retrieves the currently logged in user.
 *
 * @returns A reference to the user.
 */
export const useUser = (): typeof authInjection.user => {
  const authInjection = getInjection()
  return authInjection.user
}

/**
 * Retrieves an API instance.
 *
 * @returns An authenticated API instance.
 */
export const useApi = (): typeof authInjection.api => {
  const authInjection = getInjection()
  const api = authInjection.api
  return api
}
