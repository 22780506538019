/**
 * This file contains symbols for injecting globals.
 */

import { DeepReadonly, InjectionKey, Ref, readonly } from 'vue'

import { type User, type CompetitionMetadata } from '@/hooks'
import { Api } from '@/util/api'

export const AUTHENTICATION_INJECTION_TOKEN = Symbol('authentication') as InjectionKey<{
  user: Readonly<{user: DeepReadonly<Ref<User>>, refetch: () => Promise<void> }>,
  api: ReturnType<typeof readonly<Ref<Api>>>,
}>

export const COMPETITION_METADATA_INJECTION_TOKEN = Symbol('metadata') as
  InjectionKey<{ metadata: Readonly<Ref<CompetitionMetadata>>, refetch: () => Promise<void> }>
