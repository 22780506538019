<template>
  <transition
    mode="out-in"
    :enter-active-class="enterActiveClass"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    :leave-active-class="leaveActiveClass"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <slot />
  </transition>
</template>

<script lang="ts" setup>
  import { computed, PropType } from 'vue'

  const props = defineProps({
    duration: {
      type: String as PropType<'short' | 'long'>,
      default: 'long',
      validator(value: string) {
        return ['short', 'long'].includes(value)
      },
    },
  })

  const enterActiveClass = computed(() => {
    switch (props.duration) {
      case 'short':
        return 'duration-100 ease-out'
      default:
        return 'duration-300 ease-out'
    }
  })

  const leaveActiveClass = computed(() => {
    switch (props.duration) {
      case 'short':
        return 'duration-100 ease-in'
      default:
        return 'duration-200 ease-in'
    }
  })

</script>
