/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerChallengeScoreDto
 */
export interface HandlerChallengeScoreDto {
    /**
     * Whether the current participant has completed the challenge.
     * @type {boolean}
     * @memberof HandlerChallengeScoreDto
     */
    isCompleted: boolean;
    /**
     * The participants points.
     * @type {number}
     * @memberof HandlerChallengeScoreDto
     */
    points: number;
    /**
     * The total reachable points for this challenge.
     * @type {number}
     * @memberof HandlerChallengeScoreDto
     */
    pointsTotal: number;
    /**
     * The participant's number of solved flags.
     * @type {number}
     * @memberof HandlerChallengeScoreDto
     */
    solvedFlags: number;
    /**
     * The number of flags for the challenge.
     * @type {number}
     * @memberof HandlerChallengeScoreDto
     */
    totalFlags: number;
}

/**
 * Check if a given object implements the HandlerChallengeScoreDto interface.
 */
export function instanceOfHandlerChallengeScoreDto(value: object): value is HandlerChallengeScoreDto {
    if (!('isCompleted' in value) || value['isCompleted'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('pointsTotal' in value) || value['pointsTotal'] === undefined) return false;
    if (!('solvedFlags' in value) || value['solvedFlags'] === undefined) return false;
    if (!('totalFlags' in value) || value['totalFlags'] === undefined) return false;
    return true;
}

export function HandlerChallengeScoreDtoFromJSON(json: any): HandlerChallengeScoreDto {
    return HandlerChallengeScoreDtoFromJSONTyped(json, false);
}

export function HandlerChallengeScoreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerChallengeScoreDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isCompleted': json['isCompleted'],
        'points': json['points'],
        'pointsTotal': json['pointsTotal'],
        'solvedFlags': json['solvedFlags'],
        'totalFlags': json['totalFlags'],
    };
}

export function HandlerChallengeScoreDtoToJSON(value?: HandlerChallengeScoreDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isCompleted': value['isCompleted'],
        'points': value['points'],
        'pointsTotal': value['pointsTotal'],
        'solvedFlags': value['solvedFlags'],
        'totalFlags': value['totalFlags'],
    };
}

