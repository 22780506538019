/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The response for a single category.
 * @export
 * @interface HandlerCategoryResponse
 */
export interface HandlerCategoryResponse {
    /**
     * The description of the category.
     * @type {string}
     * @memberof HandlerCategoryResponse
     */
    description: string;
    /**
     * The full name of the category.
     * @type {string}
     * @memberof HandlerCategoryResponse
     */
    name: string;
    /**
     * The order of the category within the category list.
     * @type {number}
     * @memberof HandlerCategoryResponse
     */
    order: number;
    /**
     * The unique slug of the category.
     * @type {string}
     * @memberof HandlerCategoryResponse
     */
    slug: string;
}

/**
 * Check if a given object implements the HandlerCategoryResponse interface.
 */
export function instanceOfHandlerCategoryResponse(value: object): value is HandlerCategoryResponse {
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function HandlerCategoryResponseFromJSON(json: any): HandlerCategoryResponse {
    return HandlerCategoryResponseFromJSONTyped(json, false);
}

export function HandlerCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerCategoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'order': json['order'],
        'slug': json['slug'],
    };
}

export function HandlerCategoryResponseToJSON(value?: HandlerCategoryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'name': value['name'],
        'order': value['order'],
        'slug': value['slug'],
    };
}

