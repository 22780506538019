<template>
  <!-- Make sure the modal is always at the top level for the backdrop filter to work. -->
  <Teleport to="body">
    <InplaceReplaceTransition>
      <div
        v-if="modelValue"
        ref="modalRef"
        class="modal grid justify-items-center"
        tabindex="0"
        @click.self="closeModal"
        @keyup.prevent="onKeyUp"
      >
        <div class="flex items-center" @click.self="closeModal">
          <div class="bg-accentBg border-t-4 border-accentHover rounded-md text-white px-4 py-3 sm:mx-5 my-5 sm:my-10 max-w-4xl modal-content grow-0 h-fit">
            <div class="flex mb-3">
              <div class="flex-1 pr-9">
                <h2 class="font-bold text-xl mt-1">{{ props.title }}</h2>
              </div>
              <div v-if="props.showCloseButton" class="flex-none">
                <button class="close-button py-1 h-7 w-7" role="button" tabindex="0" @click="closeModal()">
                  <XCircleIcon class="text-white" />
                </button>
              </div>
            </div>
            <!-- Compensate for the close button. -->
            <div class="pr-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </InplaceReplaceTransition>
  </Teleport>
</template>

<script lang="ts" setup>
  import { XCircleIcon } from '@heroicons/vue/24/outline'
  import { defineProps, defineEmits, withDefaults, watch, ref, nextTick } from 'vue'

  import InplaceReplaceTransition from '@/components/ui/InplaceReplaceTransition.vue'

  const props = withDefaults(defineProps<{
    modelValue: boolean,
    title?: string,
    showCloseButton?: boolean,
  }>(), {
    modelValue: false,
    title: 'Default title',
    showCloseButton: true,
  })

  const modalRef = ref<HTMLDivElement | null>(null)

  const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

  watch(() => props.modelValue, (newValue, oldValue) => {
    nextTick(() => {
      // Focus on open for key events.
      if (newValue && !oldValue && modalRef.value) {
        modalRef.value.focus()
      }
    })
  })
  function closeModal() {
    emits('update:modelValue', false)
  }

  function onKeyUp(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      closeModal()
    }
  }
</script>

<style scoped lang="scss">
  .modal {
    @apply fixed left-0 top-0 w-full h-full z-50 bg-background bg-opacity-70 backdrop-blur-sm shadow-lg overflow-y-scroll;
  }

  .close-button:hover {
    filter: drop-shadow(1px -1px 6px rgba(255, 255, 255, 0.9));
  }
</style>
