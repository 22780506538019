/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerChallengeSolvesDto } from './HandlerChallengeSolvesDto';
import {
    HandlerChallengeSolvesDtoFromJSON,
    HandlerChallengeSolvesDtoFromJSONTyped,
    HandlerChallengeSolvesDtoToJSON,
} from './HandlerChallengeSolvesDto';

/**
 * 
 * @export
 * @interface HandlerCategorySolvesDto
 */
export interface HandlerCategorySolvesDto {
    /**
     * All solves for the challenges in that category.
     * @type {Array<HandlerChallengeSolvesDto>}
     * @memberof HandlerCategorySolvesDto
     */
    challengeSolves: Array<HandlerChallengeSolvesDto>;
    /**
     * The category's name.
     * @type {string}
     * @memberof HandlerCategorySolvesDto
     */
    name: string;
}

/**
 * Check if a given object implements the HandlerCategorySolvesDto interface.
 */
export function instanceOfHandlerCategorySolvesDto(value: object): value is HandlerCategorySolvesDto {
    if (!('challengeSolves' in value) || value['challengeSolves'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function HandlerCategorySolvesDtoFromJSON(json: any): HandlerCategorySolvesDto {
    return HandlerCategorySolvesDtoFromJSONTyped(json, false);
}

export function HandlerCategorySolvesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerCategorySolvesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'challengeSolves': ((json['challengeSolves'] as Array<any>).map(HandlerChallengeSolvesDtoFromJSON)),
        'name': json['name'],
    };
}

export function HandlerCategorySolvesDtoToJSON(value?: HandlerCategorySolvesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'challengeSolves': ((value['challengeSolves'] as Array<any>).map(HandlerChallengeSolvesDtoToJSON)),
        'name': value['name'],
    };
}

