/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerChallengeIdentifier } from './HandlerChallengeIdentifier';
import {
    HandlerChallengeIdentifierFromJSON,
    HandlerChallengeIdentifierFromJSONTyped,
    HandlerChallengeIdentifierToJSON,
} from './HandlerChallengeIdentifier';

/**
 * 
 * @export
 * @interface HandlerUserRunningChallenges
 */
export interface HandlerUserRunningChallenges {
    /**
     * The maximum number of challenge instances that are allowed to run simultaneously.
     * If unset or null, the number of challenges is not restricted.
     * @type {number}
     * @memberof HandlerUserRunningChallenges
     */
    maximum?: number | null;
    /**
     * The currently running challenge instances.
     * @type {Array<HandlerChallengeIdentifier>}
     * @memberof HandlerUserRunningChallenges
     */
    running: Array<HandlerChallengeIdentifier>;
}

/**
 * Check if a given object implements the HandlerUserRunningChallenges interface.
 */
export function instanceOfHandlerUserRunningChallenges(value: object): value is HandlerUserRunningChallenges {
    if (!('running' in value) || value['running'] === undefined) return false;
    return true;
}

export function HandlerUserRunningChallengesFromJSON(json: any): HandlerUserRunningChallenges {
    return HandlerUserRunningChallengesFromJSONTyped(json, false);
}

export function HandlerUserRunningChallengesFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerUserRunningChallenges {
    if (json == null) {
        return json;
    }
    return {
        
        'maximum': json['maximum'] == null ? undefined : json['maximum'],
        'running': ((json['running'] as Array<any>).map(HandlerChallengeIdentifierFromJSON)),
    };
}

export function HandlerUserRunningChallengesToJSON(value?: HandlerUserRunningChallenges | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maximum': value['maximum'],
        'running': ((value['running'] as Array<any>).map(HandlerChallengeIdentifierToJSON)),
    };
}

