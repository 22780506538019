/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerCategorySolvesDto } from './HandlerCategorySolvesDto';
import {
    HandlerCategorySolvesDtoFromJSON,
    HandlerCategorySolvesDtoFromJSONTyped,
    HandlerCategorySolvesDtoToJSON,
} from './HandlerCategorySolvesDto';

/**
 * 
 * @export
 * @interface HandlerSolveTimesDto
 */
export interface HandlerSolveTimesDto {
    /**
     * The categories.
     * @type {Array<HandlerCategorySolvesDto>}
     * @memberof HandlerSolveTimesDto
     */
    categories: Array<HandlerCategorySolvesDto>;
}

/**
 * Check if a given object implements the HandlerSolveTimesDto interface.
 */
export function instanceOfHandlerSolveTimesDto(value: object): value is HandlerSolveTimesDto {
    if (!('categories' in value) || value['categories'] === undefined) return false;
    return true;
}

export function HandlerSolveTimesDtoFromJSON(json: any): HandlerSolveTimesDto {
    return HandlerSolveTimesDtoFromJSONTyped(json, false);
}

export function HandlerSolveTimesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerSolveTimesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(HandlerCategorySolvesDtoFromJSON)),
    };
}

export function HandlerSolveTimesDtoToJSON(value?: HandlerSolveTimesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': ((value['categories'] as Array<any>).map(HandlerCategorySolvesDtoToJSON)),
    };
}

