<template>
  <div class="bg-primaryBg border-t-4 border-primary rounded-md text-white px-4 py-3 my-5">
    <div class="flex">
      <div class="py-1 mr-3 mt-1 h-7 w-7 flex-none">
        <CubeTransparentIcon class="text-white" />
      </div>
      <div class="flex-1 xs:pr-9">
        <p class="font-bold text-xl">
          {{ $t('general.challenge') }}&nbsp;{{ $t('general.address', props.instance.exposedEndpoints.length) }}
        </p>
        <p class="text-sm">
          {{ $t('challenge.endpoints.description', props.instance.exposedEndpoints.length) }}
        </p>

        <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3mt-3">
          <div
            v-for="endpoint in props.instance?.exposedEndpoints" :key="'[' + endpoint.ip + ']:' + endpoint.port"
            class=" bg-black bg-opacity-35 border-l-4 border-gray-400 rounded-md text-white px-3 py-3 sm:mx-3 my-3 flex items-center"
          >
            <div class="flex items-center w-full">
              <div class="h-8 w-7 py-1 mr-3 mb-1 flex-none text-white">
                <GlobeAltIcon v-if="isHTTP(endpoint)" />
                <CommandLineIcon v-else-if="isSSH(endpoint)" />
                <ServerIcon v-else />
              </div>
              <div class="flex-1">
                <p v-if="endpoint.title" class="text-muted">{{ endpoint.title }}</p>
                <p class="text-mono">{{ endpoint.ip }}{{ endpoint.port !== null ? `:${endpoint.port}` : '' }}</p>
              </div>
              <div v-if="endpoint.protocol && isHTTP(endpoint)" class=" h-7 w-6 py-1 mr-1 mb-1 flex-none">
                <a :href="endpoint.protocol.toLowerCase() + '://' + endpoint.ip + ':' + endpoint.port" target="_blank" class="text-white hover:text-white endpoint-link">
                  <ArrowTopRightOnSquareIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {
    ArrowTopRightOnSquareIcon,
    CommandLineIcon,
    CubeTransparentIcon,
    GlobeAltIcon,
    ServerIcon,
  } from '@heroicons/vue/24/outline'
  import { defineProps, PropType } from 'vue'

  import { type HandlerChallengeInstanceDto, type HandlerExposedEndpointDto } from '@/api'

  const props = defineProps({
    instance: {
      type: Object as PropType<HandlerChallengeInstanceDto>,
      required: true,
    },
  })

  const isHTTP = (endpoint: HandlerExposedEndpointDto) =>
    endpoint.protocol && ['http', 'https'].includes(endpoint.protocol.toLowerCase())
  const isSSH = (endpoint: HandlerExposedEndpointDto) =>
    endpoint.protocol && ['ssh'].includes(endpoint.protocol.toLowerCase())
</script>

<style scoped lang="scss">
  .endpoint-link:hover {
    filter: drop-shadow(1px -1px 6px rgba(255, 255, 255, 0.9));
  }
</style>
