/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserFlugzeugStatus } from './UserFlugzeugStatus';
import {
    UserFlugzeugStatusFromJSON,
    UserFlugzeugStatusFromJSONTyped,
    UserFlugzeugStatusToJSON,
} from './UserFlugzeugStatus';

/**
 * The flugzeug response for the user.
 * @export
 * @interface HandlerFlugzeugResponse
 */
export interface HandlerFlugzeugResponse {
    /**
     * The current status of the flugzeug.
     * @type {UserFlugzeugStatus}
     * @memberof HandlerFlugzeugResponse
     */
    status: UserFlugzeugStatus;
    /**
     * The timestamp at which the flugzeug will be stopped as UTC timestamp.
     * @type {string}
     * @memberof HandlerFlugzeugResponse
     */
    stopTimestamp: string;
    /**
     * The URL at which the flugzeug can be controlled.
     * @type {string}
     * @memberof HandlerFlugzeugResponse
     */
    url: string;
}

/**
 * Check if a given object implements the HandlerFlugzeugResponse interface.
 */
export function instanceOfHandlerFlugzeugResponse(value: object): value is HandlerFlugzeugResponse {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('stopTimestamp' in value) || value['stopTimestamp'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function HandlerFlugzeugResponseFromJSON(json: any): HandlerFlugzeugResponse {
    return HandlerFlugzeugResponseFromJSONTyped(json, false);
}

export function HandlerFlugzeugResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerFlugzeugResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': UserFlugzeugStatusFromJSON(json['status']),
        'stopTimestamp': json['stopTimestamp'],
        'url': json['url'],
    };
}

export function HandlerFlugzeugResponseToJSON(value?: HandlerFlugzeugResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': UserFlugzeugStatusToJSON(value['status']),
        'stopTimestamp': value['stopTimestamp'],
        'url': value['url'],
    };
}

