/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerUserScore
 */
export interface HandlerUserScore {
    /**
     * The number of solved flags.
     * @type {number}
     * @memberof HandlerUserScore
     */
    numSolvedFlags: number;
    /**
     * The user's points.
     * @type {number}
     * @memberof HandlerUserScore
     */
    points: number;
    /**
     * The user's rank.
     * @type {number}
     * @memberof HandlerUserScore
     */
    rank: number;
}

/**
 * Check if a given object implements the HandlerUserScore interface.
 */
export function instanceOfHandlerUserScore(value: object): value is HandlerUserScore {
    if (!('numSolvedFlags' in value) || value['numSolvedFlags'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    return true;
}

export function HandlerUserScoreFromJSON(json: any): HandlerUserScore {
    return HandlerUserScoreFromJSONTyped(json, false);
}

export function HandlerUserScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerUserScore {
    if (json == null) {
        return json;
    }
    return {
        
        'numSolvedFlags': json['numSolvedFlags'],
        'points': json['points'],
        'rank': json['rank'],
    };
}

export function HandlerUserScoreToJSON(value?: HandlerUserScore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numSolvedFlags': value['numSolvedFlags'],
        'points': value['points'],
        'rank': value['rank'],
    };
}

