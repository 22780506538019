/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrchestratorChallengeState = {
    StateNotRunning: 'not-running',
    StateStarting: 'starting',
    StateRunning: 'running',
    StateStopping: 'stopping'
} as const;
export type OrchestratorChallengeState = typeof OrchestratorChallengeState[keyof typeof OrchestratorChallengeState];


export function instanceOfOrchestratorChallengeState(value: any): boolean {
    for (const key in OrchestratorChallengeState) {
        if (Object.prototype.hasOwnProperty.call(OrchestratorChallengeState, key)) {
            if ((OrchestratorChallengeState as Record<string, OrchestratorChallengeState>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrchestratorChallengeStateFromJSON(json: any): OrchestratorChallengeState {
    return OrchestratorChallengeStateFromJSONTyped(json, false);
}

export function OrchestratorChallengeStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrchestratorChallengeState {
    return json as OrchestratorChallengeState;
}

export function OrchestratorChallengeStateToJSON(value?: OrchestratorChallengeState | null): any {
    return value as any;
}

