<template>
  <div
    class="bg-accentBg border-1 border-l-5 border-warning rounded-md text-white px-3 py-3 mx-1 my-5 items-center"
  >
    <div class="flex items-center w-full">
      <div class="h-8 w-7 py-1 mr-4 mb-1 flex-none">
        <QuestionMarkCircleIcon />
      </div>
      <div class="flex-1">
        <h3 class="font-bold text-xl">{{ $t('scoreGroupSelector.heading') }}</h3>
        <p v-if="!isLoading && scoreGroups.length > 1">{{ $t('scoreGroupSelector.chooseOneOf', { n: scoreGroups.length }) }}</p>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="h-32 flex justify-center items-center">
        <Spinner class="w-6 h-6" />
      </div>
    </template>
    <template v-for="scoreGroup in scoreGroups" v-else :key="scoreGroup.slug">
      <hr class="mt-5 mb-5">
      <div class="flex items-center w-full">
        <div class="h-8 w-7 py-1 mr-4 mb-1 flex-none">
          <TrophyIcon />
        </div>
        <div class="flex-1 clear-both">
          <h3 class="font-bold">{{ scoreGroup.name }}</h3>
          <Markdown :markdown="scoreGroup.description" />
        </div>
      </div>
      <button
        :disabled="processingScoreGroup"
        class="ccd-button bg-primaryBg enabled:hover:bg-primaryBgHover border-primary enabled:hover:border-primaryHighlight disabled:cursor-wait mt-4 float-right"
        @click.stop="saveScoreGroup(scoreGroup.slug)"
      >
        <InplaceReplaceTransition class="items-center inline-flex">
          <div v-if="!processingScoreGroup" class="inline-flex items-center">
            <RocketLaunchIcon class="w-6 h-6 mr-2" />
            {{ $t('scoreGroupSelector.chooseButton') }}
          </div>
          <div v-else class="inline-flex items-center">
            <Spinner class="-ml-1 mr-3 h-6 w-6" />
            {{ $t('scoreGroupSelector.savingChoise') }}
          </div>
        </InplaceReplaceTransition>
      </button>

      <div class="clear-both" />
    </template>
  </div>
</template>

<script lang="ts" setup>
  import {
    TrophyIcon,
    RocketLaunchIcon,
    QuestionMarkCircleIcon,
  } from '@heroicons/vue/24/outline'
  import { onMounted, ref } from 'vue'

  import { type HandlerScoreGroupDto } from '@/api'
  import Markdown from '@/components/MarkdownContent'
  import InplaceReplaceTransition from '@/components/ui/InplaceReplaceTransition.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import { useApi, useMetadata } from '@/hooks'

  const emit = defineEmits(['update-user-info'])

  const api = useApi()
  const { refetch: refetchMetadata } = useMetadata()

  const isLoading = ref(true)
  const scoreGroups = ref<HandlerScoreGroupDto[]>([])
  const processingScoreGroup = ref(false)

  onMounted(async () => {
    scoreGroups.value = await api.value.users.v1ScoregroupsGet()
    isLoading.value = false
  })

  async function saveScoreGroup(scoreGroup: string) {
    processingScoreGroup.value = true
    const result = await api.value.users.v1UserScoregroupPut({
      scoreGroup: {
        scoreGroup,
      },
    })

    emit('update-user-info', result)

    processingScoreGroup.value = false
    await refetchMetadata()
  }
</script>

<style scoped lang="scss">
  .ccd-button {
    @apply text-white font-bold py-2 px-4 border rounded inline-flex items-center;
  }
</style>
