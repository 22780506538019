/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerHealthResponseDetail
 */
export interface HandlerHealthResponseDetail {
    /**
     * 
     * @type {string}
     * @memberof HandlerHealthResponseDetail
     */
    description: string;
    /**
     * The API version (only when operational)
     * @type {string}
     * @memberof HandlerHealthResponseDetail
     */
    release?: string;
}

/**
 * Check if a given object implements the HandlerHealthResponseDetail interface.
 */
export function instanceOfHandlerHealthResponseDetail(value: object): value is HandlerHealthResponseDetail {
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function HandlerHealthResponseDetailFromJSON(json: any): HandlerHealthResponseDetail {
    return HandlerHealthResponseDetailFromJSONTyped(json, false);
}

export function HandlerHealthResponseDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerHealthResponseDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'release': json['release'] == null ? undefined : json['release'],
    };
}

export function HandlerHealthResponseDetailToJSON(value?: HandlerHealthResponseDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'release': value['release'],
    };
}

