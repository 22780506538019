<template>
  <div v-if="$i18n.availableLocales.length > 1" class="inline-flex items-center border overflow-hidden rounded min-w-20 m-2 p-0 relative leading-loose">
    <select
      v-model="locale"
      class="
        w-full h-full min-h-10
        px-3 py-2 m-0
        bg-inherit
        appearance-none cursor-pointer focus:outline-none disabled:opacity-75 font-bold
      "
    >
      <option v-for="l in $i18n.availableLocales" :key="`locale-${l}`" :value="l">{{ l }}</option>
    </select>
    <ChevronUpDownIcon class="w-4 h-4 absolute right-0 mr-1 pointer-events-none" />
  </div>
</template>

<script lang="ts" setup>
  import { ChevronUpDownIcon } from '@heroicons/vue/24/solid'
  import { ref, watchEffect } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { type SupportedLocale, saveLocale } from '@/util/i18n'

  const i18n = useI18n()

  const locale = ref(i18n.locale.value as SupportedLocale)

  watchEffect(() => {
    i18n.locale.value = locale.value
    saveLocale(locale.value)
  })
</script>
