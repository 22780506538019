/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The metadata response.
 * @export
 * @interface HandlerMetadataResponse
 */
export interface HandlerMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof HandlerMetadataResponse
     */
    ctfEnd: string;
    /**
     * 
     * @type {string}
     * @memberof HandlerMetadataResponse
     */
    ctfPostPlayEnd: string;
    /**
     * 
     * @type {string}
     * @memberof HandlerMetadataResponse
     */
    ctfStart: string;
    /**
     * 
     * @type {string}
     * @memberof HandlerMetadataResponse
     */
    flagPrefix: string;
}

/**
 * Check if a given object implements the HandlerMetadataResponse interface.
 */
export function instanceOfHandlerMetadataResponse(value: object): value is HandlerMetadataResponse {
    if (!('ctfEnd' in value) || value['ctfEnd'] === undefined) return false;
    if (!('ctfPostPlayEnd' in value) || value['ctfPostPlayEnd'] === undefined) return false;
    if (!('ctfStart' in value) || value['ctfStart'] === undefined) return false;
    if (!('flagPrefix' in value) || value['flagPrefix'] === undefined) return false;
    return true;
}

export function HandlerMetadataResponseFromJSON(json: any): HandlerMetadataResponse {
    return HandlerMetadataResponseFromJSONTyped(json, false);
}

export function HandlerMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerMetadataResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'ctfEnd': json['ctfEnd'],
        'ctfPostPlayEnd': json['ctfPostPlayEnd'],
        'ctfStart': json['ctfStart'],
        'flagPrefix': json['flagPrefix'],
    };
}

export function HandlerMetadataResponseToJSON(value?: HandlerMetadataResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ctfEnd': value['ctfEnd'],
        'ctfPostPlayEnd': value['ctfPostPlayEnd'],
        'ctfStart': value['ctfStart'],
        'flagPrefix': value['flagPrefix'],
    };
}

