<template>
  <NetworkErrorDisplay />
  <div
    v-if="route.name === 'login' || route.name === 'afterLoginRedirect' || route.name === 'logout' || !hasAuthenticatedApi || !hasUser"
    class="mx-auto flex justify-center w-full h-full items-center flex-col"
  >
    <Logo class="mb-8 max-w-[50rem] w-[70%] mx-auto" />
    <Spinner class="w-10" />
    <div v-if="route.name === 'login'">
      Processing login...
    </div>
    <div v-else-if="route.name == 'afterLoginRedirect'">
      Redirecting to login page...
    </div>
    <div v-else-if="route.name == 'logout'">
      Processing logout...
    </div>
    <div v-else>
      Waiting for authentication...
    </div>
  </div>
  <slot v-else />
</template>

<script setup lang="ts">
  import * as Sentry from '@sentry/vue'
  import { computed, onBeforeMount, onMounted, onUnmounted, provide, readonly, ref, watch } from 'vue'
  import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router'

  import { HandlerChallengeIdentifier } from '@/api'
  import Logo from '@/components/Logo.vue'
  import NetworkErrorDisplay from '@/components/NetworkErrorDisplay.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { usePeriodic, type User } from '@/hooks'
  import { AUTHENTICATION_INJECTION_TOKEN } from '@/injectionTokens'
  import { produceAPI } from '@/util/api'
  import { OidcAuthService } from '@/util/oidc'

  // Fill in a dummy user to ensure we always have a non-nullable user.
  const currentUser = ref<User>({
    isInitialized: false,
    id: 'unknown',
    displayName: '',
    vpnConnected: false,
    vpnIpAddress: '',
    rank: NaN,
    points: 0,
    numSolvedFlags: 0,
    runningChallengeInstances: [] as HandlerChallengeIdentifier[],
    completedChallenges: [] as HandlerChallengeIdentifier[],
    maximumChallengeInstances: null,
    hasSelectedGroup: false,
    externalId: '',
  })

  const api = ref(produceAPI(''))
  const token = ref('')
  const hasAuthenticatedApi = ref(false)
  const refetchUser = async () => {
    if (!hasAuthenticatedApi.value) {
      return null
    }
    const fetchedUser = await api.value.users.v1UserGet()
    currentUser.value = {
      isInitialized: true,
      id: fetchedUser.id,
      displayName: fetchedUser.username,
      vpnConnected: fetchedUser.vpn.connected,
      vpnIpAddress: fetchedUser.vpn.ipv4Address,
      points: fetchedUser.score?.points ?? 0,
      rank: fetchedUser.score?.rank ?? NaN,
      numSolvedFlags: fetchedUser.score?.numSolvedFlags ?? 0,
      runningChallengeInstances: fetchedUser.runningChallenges.running,
      completedChallenges: fetchedUser.completedChallenges,
      maximumChallengeInstances: fetchedUser.runningChallenges.maximum ?? null,
      hasSelectedGroup: fetchedUser.hasSelectedScoreGroup,
      externalId: fetchedUser.externalId,
    } satisfies User
    return fetchedUser
  }
  const periodicUserRefetch = usePeriodic(refetchUser, FLUGSICHERUNG_RELOAD_DATA_INTERVAL)
  const hasUser = computed(() => hasAuthenticatedApi.value && !!periodicUserRefetch.value)

  const tokenChangedListener = (newToken: string | null) => {
    if (!newToken) {
      hasAuthenticatedApi.value = false
      token.value = ''
      return
    }
    token.value = newToken
    hasAuthenticatedApi.value = true
    api.value = produceAPI(newToken)
    periodicUserRefetch.trigger()
  }

  const route = useRoute()
  const router = useRouter()

  onBeforeMount(() => {
    OidcAuthService.default().addOnTokenChangedListener(tokenChangedListener)
  })

  onUnmounted(() => {
    OidcAuthService.default().removeOnTokenChangedListener(tokenChangedListener)
  })

  const handleLogin = async () => {
    try {
      await OidcAuthService.default().login()
    } catch (ex) {
      throw new Error('error during login', { cause: ex })
    }
  }

  const handleLogout = async () => {
    try {
      await OidcAuthService.default().logout()
    } catch (ex) {
      throw new Error('error during logout', { cause: ex })
    }
  }

  const handleRedirect = async () => {
    try {
      await OidcAuthService.default().handleOidcRedirect()
      // User was successfully signed in, redirect to home.
      await router.replace({ name: 'home' })
    } catch (ex) {
      throw new Error('error handling redirect', { cause: ex })
    }
  }

  const handleAuthRoute = async (route: RouteLocationNormalized) => {
    try {
      switch (route.name) {
        case 'login': {
          await handleLogin()
          break
        }
        case 'afterLoginRedirect': {
          await handleRedirect()
          break
        }
        case 'logout': {
          await handleLogout()
          break
        }
        default:
          break
      }
    } catch (ex) {
      Sentry.captureException(ex)
      // Just retry login for now.
      await handleLogin()
    }
  }

  onMounted(async () => {
    router.beforeEach(async (route) => {
      await handleAuthRoute(route)
    })
    await handleAuthRoute(route)
  })

  watch(currentUser, (user) => {
    Sentry.getCurrentScope().setUser({
      id: user.id,
      username: user.displayName,
    })
    Sentry.setUser({
      id: user.id,
      username: user.displayName,
    })
  }, { immediate: true })

  const triggerRefetch = async () => {
    await periodicUserRefetch.trigger()
  }

  provide(AUTHENTICATION_INJECTION_TOKEN, {
    user: {
      user: readonly(currentUser),
      refetch: triggerRefetch,
    },
    api: readonly(api),
  })
</script>
