/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A flag submission.
 * @export
 * @interface HandlerFlagSubmission
 */
export interface HandlerFlagSubmission {
    /**
     * 
     * @type {string}
     * @memberof HandlerFlagSubmission
     */
    flag: string;
}

/**
 * Check if a given object implements the HandlerFlagSubmission interface.
 */
export function instanceOfHandlerFlagSubmission(value: object): value is HandlerFlagSubmission {
    if (!('flag' in value) || value['flag'] === undefined) return false;
    return true;
}

export function HandlerFlagSubmissionFromJSON(json: any): HandlerFlagSubmission {
    return HandlerFlagSubmissionFromJSONTyped(json, false);
}

export function HandlerFlagSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerFlagSubmission {
    if (json == null) {
        return json;
    }
    return {
        
        'flag': json['flag'],
    };
}

export function HandlerFlagSubmissionToJSON(value?: HandlerFlagSubmission | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'flag': value['flag'],
    };
}

