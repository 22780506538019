<template>
  <PageContentContainer>
    <template v-if="!!user.id && scoreboard && scoreboard.entries">
      <h1 class="font-bold mt-4 mb-4">{{ $t('scoreboard.heading') }}</h1>
      <table class="scoreboard-table-component">
        <thead>
          <tr>
            <th>{{ $t('scoreboard.rank') }}</th>
            <th>{{ $t('scoreboard.points') }}</th>
            <th>{{ $t('scoreboard.participant') }}</th>
            <th>{{ $t('scoreboard.numSolvedFlags') }}</th>
          </tr>
        </thead>
        <TransitionGroup tag="tbody" name="scoreboard-entries">
          <tr
            v-for="scoreboardEntry in scoreboard.entries"
            :key="scoreboardEntry.participantId"
            :class="{
              'active-row': user.id === scoreboardEntry.participantId,
              'first-rank': scoreboardEntry.rank === 1 && scoreboardEntry.points > 0,
              'second-rank': scoreboardEntry.rank === 2 && scoreboardEntry.points > 0,
              'third-rank': scoreboardEntry.rank === 3 && scoreboardEntry.points > 0,
            // 'non-participant': !!user.id
            //   && user.id === scoreboardEntry.participantId
            // TODO: Implement when competition groups are implemented.
            // && user.competitionParticipantStatus !== UserInfoResponseCompetitionParticipantStatusEnum.Yes
            }"
          >
            <td>{{ isNaN(scoreboardEntry.rank) ? 'N/A' : `#${scoreboardEntry.rank}` }}</td>
            <td><strong>{{ scoreboardEntry.points }}</strong></td>
            <td>
              {{ scoreboardEntry.participantName }}
              <span
                v-if="!!user.id && user.id === scoreboardEntry.participantId"
                class="inline ml-1 text-[0.6rem] align-middle leading-none bg-primary text-white px-2 py-1 rounded-md"
              >
                {{ $t('scoreboard.userMarker') }}
              </span>
            </td>
            <td>{{ scoreboardEntry.flags }}</td>
          </tr>
        </TransitionGroup>
      </table>
    </template>
    <div v-else class="w-full">
      <Spinner class="mx-auto w-6 h-6" />
    </div>
  </PageContentContainer>
</template>

<script setup lang="ts">
  import { ref, computed, toRaw } from 'vue'

  import { type HandlerScoreboardDto } from '@/api'
  import PageContentContainer from '@/components/PageContentContainer.vue'
  import Spinner from '@/components/ui/Spinner.vue'
  import { FLUGSICHERUNG_RELOAD_DATA_INTERVAL } from '@/constants'
  import { useApi, usePeriodic, useUser } from '@/hooks'

  const { user } = useUser()
  const api = useApi()

  usePeriodic(loadScoreboard, FLUGSICHERUNG_RELOAD_DATA_INTERVAL)
  const scoreboardData = ref<HandlerScoreboardDto | null>(null)

  async function loadScoreboard() {
    scoreboardData.value = await api.value.score.v1StatsScoreboardGet()
  }

  const scoreboard = computed(() => {
    const currentUser = user.value
    if (!currentUser.id || !scoreboardData.value) {
      return
    }
    const tmpScoreboardData = structuredClone(toRaw(scoreboardData.value))
    // We also want to show the ranks of the users themselves
    // even if they are no not in the top ranked participants.
    // Thus, we add an entry to the scoreboard.
    const participantInScoreboard = tmpScoreboardData.entries.find((entry) => entry.participantId === currentUser.id)
    if (!participantInScoreboard) {
      const positionForRank = tmpScoreboardData.entries.findIndex((entry) => entry.rank >= currentUser.rank)
      const participantEntry = {
        participantId: currentUser.id,
        participantName: currentUser.displayName,
        points: currentUser.points,
        rank: currentUser.rank,
        flags: currentUser.numSolvedFlags,
      }
      if (positionForRank === -1) {
        // Add the participant's entry in the end.
        tmpScoreboardData.entries.push(participantEntry)
      } else {
        // Insert the new entry at the correct position.
        tmpScoreboardData.entries = [
          ...tmpScoreboardData.entries.slice(0, positionForRank),
          participantEntry,
          ...tmpScoreboardData.entries.slice(positionForRank),
        ]
      }
    }

    return tmpScoreboardData
  })
</script>

<style lang="scss">
.scoreboard-table-component {
  @apply border-separate border-spacing-0 w-full;
  font-size: 0.9em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  th, td {
    @apply px-6 py-2 text-center;
  }

  thead tr {
    @apply text-white text-left border-b-dark-50 border-b;
  }

  thead th {
    @apply bg-primaryBg;
  }

  thead th:first-child {
    @apply rounded-tl-md;
  }

  thead th:last-child {
    @apply rounded-tr-md;
  }

  tbody tr {
    @apply text-center border-b border-b;
  }

  tbody tr:nth-of-type(even) {
    @apply bg-dark-600;
  }

  /** First three places are highlighted. */
  tbody tr:not(.non-participant).first-rank {
    @apply bg-amber-400 text-dark-800;
  }

  tbody tr:not(.non-participant).second-rank {
    @apply bg-zinc-500;
  }

  tbody tr:not(.non-participant).third-rank {
    @apply bg-amber-700;
  }

  tr:last-of-type td {
    @apply border-b-primaryBg border-b-2;
  }

  tr.active-row {
    @apply font-extrabold;
    td {
      @apply border-t-2 border-b-2 border-primary;
    }

    td:first-child {
      @apply border-l-2;
    }

    td:last-child {
      @apply border-r-2;
    }
  }
  .scoreboard-entries-enter-active, .scoreboard-entries-leave-active {
    transition: all 1s;
  }
  .scoreboard-entries-enter, .scoreboard-entries-leave-to {
    opacity: 0;
    transform: translateX(50px);
    transition: all 500ms;
  }
  .scoreboard-entries-move {
    transition: transform 500ms;
  }
}
</style>
