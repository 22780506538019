/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerFlagSolveDto } from './HandlerFlagSolveDto';
import {
    HandlerFlagSolveDtoFromJSON,
    HandlerFlagSolveDtoFromJSONTyped,
    HandlerFlagSolveDtoToJSON,
} from './HandlerFlagSolveDto';

/**
 * 
 * @export
 * @interface HandlerChallengeSolvesDto
 */
export interface HandlerChallengeSolvesDto {
    /**
     * All solves for the flags in that challenge.
     * @type {Array<HandlerFlagSolveDto>}
     * @memberof HandlerChallengeSolvesDto
     */
    flagSolves: Array<HandlerFlagSolveDto>;
    /**
     * The challenge's name.
     * @type {string}
     * @memberof HandlerChallengeSolvesDto
     */
    name: string;
}

/**
 * Check if a given object implements the HandlerChallengeSolvesDto interface.
 */
export function instanceOfHandlerChallengeSolvesDto(value: object): value is HandlerChallengeSolvesDto {
    if (!('flagSolves' in value) || value['flagSolves'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function HandlerChallengeSolvesDtoFromJSON(json: any): HandlerChallengeSolvesDto {
    return HandlerChallengeSolvesDtoFromJSONTyped(json, false);
}

export function HandlerChallengeSolvesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerChallengeSolvesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'flagSolves': ((json['flagSolves'] as Array<any>).map(HandlerFlagSolveDtoFromJSON)),
        'name': json['name'],
    };
}

export function HandlerChallengeSolvesDtoToJSON(value?: HandlerChallengeSolvesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'flagSolves': ((value['flagSolves'] as Array<any>).map(HandlerFlagSolveDtoToJSON)),
        'name': value['name'],
    };
}

