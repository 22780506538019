/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlerExposedEndpointDto
 */
export interface HandlerExposedEndpointDto {
    /**
     * The IP address of the exposed port.
     * @type {string}
     * @memberof HandlerExposedEndpointDto
     */
    ip: string;
    /**
     * The port itself.
     * If empty, only the IP is to be exposed.
     * @type {number}
     * @memberof HandlerExposedEndpointDto
     */
    port: number | null;
    /**
     * The name of the protocol the port is supposed to provide.
     * @type {string}
     * @memberof HandlerExposedEndpointDto
     */
    protocol: string | null;
    /**
     * A short title explaining what this port is supposed to provide.
     * @type {string}
     * @memberof HandlerExposedEndpointDto
     */
    title: string;
}

/**
 * Check if a given object implements the HandlerExposedEndpointDto interface.
 */
export function instanceOfHandlerExposedEndpointDto(value: object): value is HandlerExposedEndpointDto {
    if (!('ip' in value) || value['ip'] === undefined) return false;
    if (!('port' in value) || value['port'] === undefined) return false;
    if (!('protocol' in value) || value['protocol'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function HandlerExposedEndpointDtoFromJSON(json: any): HandlerExposedEndpointDto {
    return HandlerExposedEndpointDtoFromJSONTyped(json, false);
}

export function HandlerExposedEndpointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerExposedEndpointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'ip': json['ip'],
        'port': json['port'],
        'protocol': json['protocol'],
        'title': json['title'],
    };
}

export function HandlerExposedEndpointDtoToJSON(value?: HandlerExposedEndpointDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ip': value['ip'],
        'port': value['port'],
        'protocol': value['protocol'],
        'title': value['title'],
    };
}

